import React, { useRef, useState, useMemo, useEffect } from 'react'
import {
  MenuItem,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
} from '@mui/material'
import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import Select from '@atoms/FBSelect'
import { theme } from '@firstbase/muiTheme'
import {
  useRegionCurrency,
  useSupportedCurrencies,
  useSupportedRegions,
} from '@utils/contractUtils'

type OwnProps = {
  formCurrenciesByCountry: Record<string, string>
  updateCountryCurrency: (countryCode: string, currency: string) => void
}

type SupportedCountriesArray = {
  countryCode: string
  regionName: string
}[]

function CurrenciesTableForm({
  formCurrenciesByCountry,
  updateCountryCurrency,
}: OwnProps) {
  // freeze initial form currencies to control disabled regions
  const initialFormCurrenciesByCountry = useRef(formCurrenciesByCountry)

  const supportedRegions = useSupportedRegions()
  const supportedCurrencies = useSupportedCurrencies()
  const regionCurrency = useRegionCurrency()

  const allSupportedCountries = useMemo(
    () =>
      Object.entries(supportedRegions).map(([countryCode, regionName]) => ({
        countryCode: countryCode,
        regionName: regionName,
      })),
    [supportedRegions]
  )
  const [supportedCountriesInitialized, setSupportedCountriesInitialized] =
    useState(false)
  const [configuredSupportedCountries, setConfiguredSupportedCountries] =
    useState<SupportedCountriesArray>([])
  const [nonConfiguredSupportedCountries, setNonConfiguredSupportedCountries] =
    useState<SupportedCountriesArray>([])

  const addNewSupportedCountry = (countryCode: string) => {
    const foundEntry: { countryCode: string; regionName: string } | undefined =
      allSupportedCountries.find(
        (country) => country.countryCode === countryCode
      )
    if (foundEntry) {
      setConfiguredSupportedCountries((prevConfiguredCountries) => {
        return [
          ...prevConfiguredCountries,
          {
            countryCode: foundEntry?.countryCode,
            regionName: foundEntry?.regionName,
          },
        ]
      })
    }

    setNonConfiguredSupportedCountries((prevNonConfiguredCountries) =>
      prevNonConfiguredCountries.filter(
        (country) => country.countryCode !== countryCode
      )
    )
    updateCountryCurrency(countryCode, regionCurrency[countryCode].acronym)
  }

  /**
   * Quick hack to reinitialize the state variables after the
   * queries resolve, upon mounting the component
   */
  useEffect(() => {
    if (!supportedCountriesInitialized && allSupportedCountries.length > 0) {
      setConfiguredSupportedCountries(() =>
        allSupportedCountries.filter(({ countryCode }) =>
          initialFormCurrenciesByCountry.current.hasOwnProperty(countryCode)
        )
      )
      setNonConfiguredSupportedCountries(() =>
        allSupportedCountries.filter(
          ({ countryCode }) =>
            !initialFormCurrenciesByCountry.current.hasOwnProperty(countryCode)
        )
      )
      setSupportedCountriesInitialized(true)
    }
  }, [allSupportedCountries, supportedCountriesInitialized])

  return (
    <>
      <Typography sx={{ fontWeight: 500, marginBottom: 1.5 }}>
        Supported regions & currency
      </Typography>
      <Card variant="outlined" sx={{ maxWidth: '858px', marginBottom: 4 }}>
        <TableContainer>
          <Table aria-label="currency/region table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: '16px 8px 8px 16px' }}>
                  <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                    Region
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: '16px 8px 8px 16px' }}>
                  <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                    Charged currency
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configuredSupportedCountries.map(
                ({ countryCode, regionName }) => (
                  <TableRow
                    key={`Supported Region - ${countryCode}`}
                    data-testid={`Supported Region Row - ${countryCode}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ padding: 1.5, width: '50%' }}>
                      <Typography variant="inherit">{regionName}</Typography>
                    </TableCell>
                    <TableCell sx={{ padding: 1.5, width: '50%' }}>
                      <FormControl fullWidth>
                        <InputLabel
                          size="small"
                          id={`${countryCode}-currency-label`}
                        >
                          Currency
                        </InputLabel>
                        <Select
                          labelId={`${countryCode}-currency-label`}
                          id={`${countryCode}-currency-select`}
                          value={
                            formCurrenciesByCountry[countryCode] ||
                            NOT_SUPPORTED
                          }
                          onChange={(e) =>
                            updateCountryCurrency(countryCode, e.target.value)
                          }
                          disabled={
                            !!initialFormCurrenciesByCountry.current[
                              countryCode
                            ]
                          }
                          label="Currency"
                          size="small"
                          data-testid={`Supported Region Dropdown - ${countryCode}`}
                          inputProps={{
                            'data-testid': `Supported Region Select - ${countryCode}`,
                          }}
                        >
                          {Object.keys(supportedCurrencies).map(
                            (currency: String) => (
                              <MenuItem
                                value={currency as any}
                                key={`${countryCode} - ${currency}`}
                              >
                                {currency}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                )
              )}
              {!!nonConfiguredSupportedCountries.length && (
                <TableRow
                  key={`Add Region`}
                  data-testid={`Add Supported Region Row`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: theme.palette.grey[300],
                  }}
                >
                  <TableCell>
                    <Typography sx={{ fontWeight: 500 }}>
                      Add new region
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: 1.5, width: '50%' }}>
                    <FormControl fullWidth>
                      <InputLabel size="small" id={`add-region-label`}>
                        Select region
                      </InputLabel>
                      <Select
                        labelId={`add-region-label`}
                        id={`add-region-select`}
                        onChange={(e) =>
                          addNewSupportedCountry(e.target.value + '')
                        }
                        label="Select region"
                        size="small"
                      >
                        {nonConfiguredSupportedCountries.map(
                          ({ countryCode, regionName }) => (
                            <MenuItem
                              value={countryCode as any}
                              key={`${regionName}`}
                            >
                              {regionName}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}

export default CurrenciesTableForm
