import { useMutation } from '@apollo/client'
import {
  createProductTier as CreateProductTier,
  createProductTierVariables,
} from '@firstbase/data/ProductTier/__generated__/createProductTier'
import {
  updateProductTierFeatures as UpdateProductTierFeatures,
  updateProductTierFeaturesVariables,
} from '@firstbase/data/ProductTier/__generated__/updateProductTierFeatures'
import {
  updateProductTierStatus as UpdateProductTierStatus,
  updateProductTierStatusVariables,
} from '@firstbase/data/ProductTier/__generated__/updateProductTierStatus'
import {
  CREATE_PRODUCT_TIER,
  UPDATE_PRODUCT_TIER_FEATURES,
  UPDATE_PRODUCT_TIER_STATUS,
} from '@firstbase/data/ProductTier/productTierMutations'
import { GET_ALL_PRODUCT_TIERS } from '@firstbase/data/ProductTier/productTierQueries'

export const useProductTierMutations = () => {
  const [createProductTier, { loading: isCreateProductTierLoading }] =
    useMutation<CreateProductTier, createProductTierVariables>(
      CREATE_PRODUCT_TIER,
      { refetchQueries: [GET_ALL_PRODUCT_TIERS] }
    )

  const [
    updateProductTierStatus,
    { loading: isUpdateProductTierStatusLoading },
  ] = useMutation<UpdateProductTierStatus, updateProductTierStatusVariables>(
    UPDATE_PRODUCT_TIER_STATUS,
    { refetchQueries: [GET_ALL_PRODUCT_TIERS] }
  )

  const [
    updateProductTierFeatures,
    { loading: isUpdateProductTierFeaturesLoading },
  ] = useMutation<
    UpdateProductTierFeatures,
    updateProductTierFeaturesVariables
  >(UPDATE_PRODUCT_TIER_FEATURES, { refetchQueries: [GET_ALL_PRODUCT_TIERS] })

  return {
    createProductTier,
    isCreateProductTierLoading,

    updateProductTierStatus,
    isUpdateProductTierStatusLoading,

    updateProductTierFeatures,
    isUpdateProductTierFeaturesLoading,
  }
}
