import { useRef, useCallback, useState, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { GET_ALL_SKUS } from '@firstbase/data/SKU/queries'
import {
  getAllSKUs,
  getAllSKUsVariables,
  getAllSKUs_getAllSKUs_data,
} from '@firstbase/data/SKU/__generated__/getAllSKUs'

import { ReducerState } from './types'

const PAGE_SIZE = 25

interface HookProps {
  metadataFields: ReducerState['metadataFields']
  vendorCode: string
}

export const useFilteredSkuListData = ({
  metadataFields,
  vendorCode,
}: HookProps) => {
  const metadata = useMemo(
    () =>
      Object.entries(metadataFields).map(([_, { value, name }]) => ({
        field: name,
        value,
      })),
    [metadataFields]
  )

  const { data, error, loading, fetchMore } = useQuery<
    getAllSKUs,
    getAllSKUsVariables
  >(GET_ALL_SKUS, {
    variables: {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      filter: {
        categories: ['WARRANTY'],
        brands: [vendorCode],
        metadata,
      },
    },
  })

  const [hasMore, setHasMore] = useState(true)
  const [filteredSkus, setFilteredSkus] = useState<
    getAllSKUs_getAllSKUs_data[]
  >([])

  const pageNumberRef = useRef(1)

  const handleLoadMore = useCallback(async () => {
    try {
      const { data: fetchMoreData } = await fetchMore({
        variables: {
          pageNumber: pageNumberRef.current + 1,
          pageSize: PAGE_SIZE,
        },
      })

      pageNumberRef.current += 1

      setHasMore(hasMoreMatches(fetchMoreData))
      setFilteredSkus((prevSkus) => [
        ...prevSkus,
        ...fetchMoreData.getAllSKUs.data,
      ])
    } catch (fetchMoreError) {
      // eslint-disable-next-line no-console
      console.error(fetchMoreError)
    }
  }, [fetchMore])

  /**
   * Handle updates to the data list from the use query hook,
   * which happens when the user updates the metadata field
   * selections or the category is changed.
   */
  useEffect(() => {
    setHasMore(!!data?.getAllSKUs && hasMoreMatches(data))
    setFilteredSkus(data?.getAllSKUs.data || [])
    pageNumberRef.current = 1
  }, [data?.getAllSKUs.data])

  return {
    filteredSkus,
    filteredSKUsError: error,
    filteredSKUsLoading: loading,
    totalElements: data?.getAllSKUs.totalElements ?? 0,
    hasMore,
    handleLoadMore,
  }
}

const hasMoreMatches = (data: getAllSKUs) =>
  data.getAllSKUs.data.length > 0 || data.getAllSKUs.data.length === PAGE_SIZE
