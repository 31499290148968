import { FeeBillingType } from '@firstbase/types/Fee'

export enum FeeActionTypes {
  DEFAULT = 'DEFAULT',
  OVERRIDE_FEES_CURRENCY = 'OVERRIDE_FEES_CURRENCY',
  UPDATE_CONTRACT_VERSION = 'UPDATE_CONTRACT_VERSION',
  UPDATE_FEE = 'UPDATE_FEE',
}

export type FeeActions =
  | {
      type: FeeActionTypes.UPDATE_FEE
      payload: {
        countryCode: string
        feeType: string
        price: number | string
        overrideCurrencyCode: string | undefined
        currencyCodeMap: Record<string, string>
      }
    }
  | {
      type: FeeActionTypes.DEFAULT
    }
  | {
      type: FeeActionTypes.OVERRIDE_FEES_CURRENCY
      payload: {
        currencyCode: string | undefined
        billingType?: FeeBillingType
      }
    }
  | {
      type: FeeActionTypes.UPDATE_CONTRACT_VERSION
      payload: {
        version: number
      }
    }
