import { ReactComponent as ChevronDown } from '@firstbase/assets/ChevronDown.svg'
import { CurrencyRow } from '@firstbase/types/Currency'
import { FeesRow, FeeType } from '@firstbase/types/Fee'
import { sortByReverseAlpha } from '@firstbase/utils/contractUtils'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isOverridden, isOverriddenWithDifferentPrices } from '../settingsUtils'

type OwnProps = {
  title: string
  currencies?: CurrencyRow[]
  isContractLoading: boolean
  fees?: FeesRow[]
}

const FeesAccordion = ({
  title,
  currencies = [],
  fees = [],
  isContractLoading,
}: OwnProps) => {
  // boolean to indicate whether override or not
  const [allFeesSame, setAllFeesSame] = useState<boolean>(false)

  const feesByCountryByName: Record<
    string,
    Record<FeeType, FeesRow>
  > = useMemo(() => {
    const feesByCountry: Record<string, Record<string, FeesRow>> = {}
    if (!fees.length) {
      return feesByCountry
    }

    fees.forEach((feesRow: FeesRow) => {
      if (!feesByCountry[feesRow.countryCode]) {
        feesByCountry[feesRow.countryCode] = {}
      }
      feesByCountry[feesRow.countryCode][feesRow.feeType] = feesRow
    })

    return feesByCountry
  }, [fees])

  const currencyCodeMap = useMemo(
    () =>
      currencies.reduce(
        (acc, currency) => ({
          ...acc,
          [currency.countryCode]: currency.currency,
        }),
        {}
      ),
    [currencies]
  )

  const getFeeForCountryCode: (
    currency: CurrencyRow,
    feeType: FeeType
  ) => string = useCallback(
    (currency: CurrencyRow, feeType: FeeType) => {
      const feesForCountry: Record<FeeType, FeesRow> =
        feesByCountryByName[currency.countryCode]
      if (!feesForCountry || !feesForCountry[feeType]) return '-'

      return feesForCountry[feeType].price.toLocaleString(undefined, {
        currency: currency.currency,
        style: 'currency',
      })
    },
    [feesByCountryByName]
  )

  const feesGroupedByType: {
    [type in FeeType]: Pick<FeesRow, 'currencyCode' | 'price'>[]
  } = useMemo(
    () =>
      fees.reduce((acc, fee) => {
        const { feeType, currencyCode, price } = fee
        const groupFee = { currencyCode, price }
        const feeTypeGroup = acc[feeType]
          ? [...acc[feeType], groupFee]
          : [groupFee]

        return {
          ...acc,
          [feeType]: feeTypeGroup,
        }
      }, {} as any),
    [fees]
  )

  useEffect(
    () =>
      setAllFeesSame(
        !!isOverridden(
          Object.values(feesByCountryByName).map(Object.values),
          fees
        ) || isOverriddenWithDifferentPrices([fees], currencyCodeMap)
      ),
    [fees, feesByCountryByName, currencyCodeMap]
  )

  const feesToList = useMemo(
    () => Object.keys(feesGroupedByType).sort(sortByReverseAlpha) as FeeType[],
    [feesGroupedByType]
  )

  const overrideFee = fees[0] || {}

  /**
   * HACKY wrapper used to render new case -- the case rendered within the if block --
   * easily.  By easily, it was easier to do it this way, then try to make it fit within
   * the "current" code, which is what is rendered after the if statement.  Essentially,
   * at the time of this writing, this was the fastest and safest way to do it, even though
   * code is not DRY, by any means.
   */
  const renderTableBody = () => {
    if (allFeesSame && isOverriddenWithDifferentPrices([fees], currencyCodeMap))
      return fees.map((fee) => (
        <TableRow
          key={`${fee.feeType}-${fee.countryCode}-${fee.price}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell sx={{ padding: '6px' }}>
            {fee.feeType} - {fee.countryCode}
          </TableCell>
          <TableCell
            sx={{ padding: '6px' }}
            key={fee.feeType + overrideFee.countryCode}
            data-testid={fee.feeType}
          >
            {getFeeForCountryCode(
              {
                ...fee,
                currency: overrideFee.currencyCode,
              } as unknown as CurrencyRow,
              fee.feeType
            )}
          </TableCell>
        </TableRow>
      ))

    return feesToList.map((feeType: FeeType) => {
      return (
        <TableRow
          key={feeType}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell sx={{ padding: '6px' }}>{feeType}</TableCell>
          {allFeesSame && (
            <TableCell
              sx={{ padding: '6px' }}
              key={feeType + overrideFee.countryCode}
              data-testid={feeType}
            >
              {getFeeForCountryCode(
                {
                  ...overrideFee,
                  currency: overrideFee.currencyCode,
                } as unknown as CurrencyRow,
                feeType
              )}
            </TableCell>
          )}
          {!allFeesSame &&
            currencies.map((currency: CurrencyRow) => (
              <TableCell
                sx={{ padding: '6px' }}
                key={feeType + currency.countryCode}
                data-testid={`${feeType}-${currency.countryCode}`}
              >
                {getFeeForCountryCode(currency, feeType)}
              </TableCell>
            ))}
        </TableRow>
      )
    })
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ChevronDown fill="#191A1B" width="1rem" height="1rem" />}
        aria-controls="service fees content"
        id="service fees header"
        disabled={isContractLoading}
      >
        <Typography>
          {isContractLoading ? <Skeleton width="12rem" /> : title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table aria-label={`${title}-table`}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: '6px' }}>
                  <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                    Fees
                  </Typography>
                </TableCell>
                {allFeesSame && (
                  <TableCell sx={{ padding: '6px' }}>
                    <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                      Prices charged in {fees[0]?.currencyCode}
                    </Typography>
                  </TableCell>
                )}
                {!allFeesSame &&
                  currencies.map((currency: CurrencyRow) => (
                    <TableCell
                      sx={{ padding: '6px' }}
                      key={currency.countryCode}
                    >
                      <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                        {currency.countryCode}
                      </Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}

export default FeesAccordion
