import React, { useCallback, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import Loader from '@firstbase/components/atoms/Loader'
import SectionError from '@firstbase/components/atoms/SectionError'
import Table from '@firstbase/components/atoms/Table'
import { PageQueryParams } from '@firstbase/components/atoms/Table/Table'
import FLAGS from '@firstbase/constants/featureFlags'
import {
  getOrganizationSubscriptionDetails,
  getOrganizationSubscriptionDetailsVariables,
} from '@firstbase/data/Organization/__generated__/getOrganizationSubscriptionDetails'
import { GET_ORGANIZATION_SUBSCRIPTION_DETAILS } from '@firstbase/data/Organization/queries'
import {
  getSKU,
  getSKUVariables,
  getSKU_getSKU_standardPricing,
} from '@firstbase/data/SKU/__generated__/getSKU'
import { GET_SKU } from '@firstbase/data/SKU/queries'
import { useFeatureFlag } from '@firstbase/hooks/useFeatureFlag'
import { CurrencyRow } from '@firstbase/types/Currency'
import { Supplier } from '@firstbase/types/Product'
import { getComparator } from '@firstbase/utils/tableUtils'
import { Divider } from '@mui/material'
import { MinimumInventory } from '__generated__/globalTypes'
import MinInventoryForm from '../MinInventoryForm'
import { editPricingTableColumns } from '../pricingTableColumns'
import { BillingType, PricingRow } from '../Product'
import {
  CustomerSuppliedCheckbox,
  FirstbaseSuppliedCheckbox,
} from '../SupplierCheckboxes'
import { Product } from './AddProduct'

type OwnProps = {
  clientId: string
  clientName: string
  currencyData: CurrencyRow[]
  selectedProduct: Product
  setPricing: (pricingInfo: PricingRow[]) => void
  submitError?: Error
  minInventory: MinimumInventory[] | null
  setMinInventory: React.Dispatch<
    React.SetStateAction<MinimumInventory[] | null>
  >
  /**
   * Pass in supplier, rather than something like firstbaseSupplied, since at first form is loaded without either value
   * This makes it easier to load as unchecked
   */
  supplier: Supplier | null
  setSupplier: React.Dispatch<React.SetStateAction<Supplier | null>>
  allowSubscription: boolean
}

type PricingRows = { [key: string]: PricingRow }

const findStandardPricing = (
  regionCode: string,
  standardPricing: getSKU_getSKU_standardPricing[]
): getSKU_getSKU_standardPricing | null => {
  return standardPricing.find((p) => p.regionCode === regionCode) || null
}

const getStandardPriceForBillingType = (
  standardPrice: getSKU_getSKU_standardPricing,
  billingType: BillingType,
  clientSubscriptionLength?: number
) => {
  if (billingType === 'Purchased') {
    return Number(standardPrice.purchasePrice) || 0
  }

  if (!clientSubscriptionLength) {
    return 0
  }

  // get subscription price based on the client's subscription length if available
  const subscriptionPrice = standardPrice.subscriptionPricing?.find(
    (subscription) => subscription.length === clientSubscriptionLength
  )
  return Number(subscriptionPrice?.price) || 0
}

const constructPricingRows = (
  selectedProduct: Product,
  currencyData: CurrencyRow[],
  clientId: string,
  allowSubscription: boolean,
  standardPricing?: getSKU_getSKU_standardPricing[]
): PricingRows => {
  const pricingRows: PricingRows = {}
  if (!selectedProduct) return pricingRows

  currencyData.forEach((currency: CurrencyRow) => {
    const standardPrice = findStandardPricing(
      currency.countryCode,
      standardPricing || []
    )

    pricingRows[currency.countryCode] = {
      orgId: clientId,
      skuId: selectedProduct.id,
      countryCode: currency.countryCode,
      id: currency.countryCode, // Included for unique-key purposes
      price: standardPrice?.purchasePrice || 0,
      currency: currency.currency,
      billingType:
        standardPrice || !allowSubscription ? 'Purchased' : 'Subscription',
    }
  })

  return pricingRows
}

function AddProductDetails({
  clientId,
  clientName,
  selectedProduct,
  setPricing,
  currencyData,
  submitError,
  setMinInventory,
  supplier,
  setSupplier,
  allowSubscription,
}: OwnProps) {
  const standardCatalogM1Flag = useFeatureFlag(
    FLAGS.SE_4591_STANDARD_CATALOG_M1
  )
  const [pricingRows, setPricingRows] = useState<PricingRows>({})

  const { data: skuData, loading: skuLoading } = useQuery<
    getSKU,
    getSKUVariables
  >(GET_SKU, {
    variables: {
      id: selectedProduct.id,
    },
    defaultOptions: {
      fetchPolicy: 'no-cache',
    },
    skip: !standardCatalogM1Flag,
  })

  const { data: { getOrganizationById: organization } = {} } = useQuery<
    getOrganizationSubscriptionDetails,
    getOrganizationSubscriptionDetailsVariables
  >(GET_ORGANIZATION_SUBSCRIPTION_DETAILS, {
    variables: { orgId: clientId },
  })

  const getStandardPricing = useCallback(():
    | getSKU_getSKU_standardPricing[]
    | undefined => {
    return skuData?.getSKU.standardPricing || undefined
  }, [skuData])

  const handleBillTypeChange = (pricingRow: PricingRow) => {
    return (newBillingType: BillingType) => {
      const updatedPricingRow = {
        ...pricingRows[pricingRow.countryCode],
        billingType: newBillingType,
      }
      const standardPrice = standardCatalogM1Flag
        ? findStandardPricing(
            pricingRow.countryCode,
            getStandardPricing() || []
          )
        : null

      if (standardPrice) {
        updatedPricingRow.price = getStandardPriceForBillingType(
          standardPrice,
          newBillingType,
          organization?.subscriptionLength ?? undefined
        )
      }

      const updatedPrices = {
        ...pricingRows,
        [pricingRow.countryCode]: updatedPricingRow,
      }

      setPricingRows(updatedPrices)
    }
  }

  const handlePriceChange =
    (pricingRow: PricingRow, validate?: boolean) => (e: any) => {
      const {
        target: { value: priceValue },
      } = e
      let cleanValue = priceValue

      // prevent more than 2 decimal places
      let validPrice = new RegExp(
        /^([0-9]+\.?[0-9]{0,2}|\.[0-9]{0,2})?$/m
      ).test(priceValue)

      if (validate && priceValue && validPrice) {
        cleanValue = Number(priceValue).toFixed(2)
      } else {
        if (!validPrice) {
          return
        }
      }

      const updatedPrices = {
        ...pricingRows,
        [pricingRow.countryCode]: {
          ...pricingRows[pricingRow.countryCode],
          price: validate ? Number(cleanValue) : cleanValue,
        },
      }

      setPricingRows(updatedPrices)
    }

  useEffect(() => {
    if (supplier === 'customer' && !skuData?.getSKU.standardCatalog) {
      /**
       * Useful for edge case where user previously selected
       * firstbase, set prices, and then switched to customer.
       * In this scenario, the pricing fields will be disabled
       * and should be reset.
       */
      setPricingRows(
        constructPricingRows(
          selectedProduct,
          currencyData,
          clientId,
          allowSubscription
        )
      )
    }
  }, [
    supplier,
    selectedProduct,
    currencyData,
    clientId,
    allowSubscription,
    skuData,
  ])

  useEffect(() => {
    setPricing(
      Object.values(pricingRows).map((priceRow) => ({
        ...priceRow,
        price: Number(priceRow.price) || 0,
        billingType: priceRow.billingType,
      }))
    )
  }, [pricingRows, setPricing])

  useEffect(() => {
    if (!skuData && standardCatalogM1Flag) return

    setPricingRows(
      constructPricingRows(
        selectedProduct,
        currencyData,
        clientId,
        allowSubscription,
        getStandardPricing()
      )
    )
  }, [
    skuData,
    selectedProduct,
    currencyData,
    clientId,
    allowSubscription,
    standardCatalogM1Flag,
    getStandardPricing,
  ])

  const fetch = useCallback(
    ({ activeSortId, activeSortDirection }: PageQueryParams) =>
      setPricingRows((prevPricingRows) => {
        const pricesArray = Object.entries(prevPricingRows).map(
          ([key, priceRow]) => ({ key, ...priceRow })
        )

        return pricesArray
          .sort(getComparator(activeSortDirection, activeSortId || ''))
          .reduce(
            (priceRows, { key, ...priceRow }) => ({
              ...priceRows,
              [key]: priceRow,
            }),
            {}
          )
      }),
    []
  )

  if (!selectedProduct) {
    return (
      <SectionError
        title="Missing Product"
        body="Please select a product first"
      />
    )
  }

  const client = { name: clientName, id: clientId }

  if (skuLoading) return <Loader />

  return (
    <>
      <CustomerSuppliedCheckbox
        checked={supplier === 'customer'}
        onChange={() => setSupplier('customer')}
      />
      <FirstbaseSuppliedCheckbox
        checked={supplier === 'firstbase'}
        onChange={() => setSupplier('firstbase')}
      />
      <Table
        query={{
          data: { data: Object.values(pricingRows) },
          fetch,
        }}
        tableId="add prices table"
        noDataMessage="No billing regions available"
        columns={editPricingTableColumns(
          handlePriceChange,
          handleBillTypeChange,
          allowSubscription,
          supplier !== 'firstbase'
        )}
      />
      <Divider sx={{ my: 4 }} />
      <MinInventoryForm
        skuName={selectedProduct.title}
        org={client}
        setMinInventory={setMinInventory}
        firstbaseSupplied={supplier === 'firstbase'}
      />
      {submitError && (
        <SectionError
          sx={{ mt: 2 }}
          title="Unable to save new prices"
          error={submitError}
        />
      )}
    </>
  )
}

export default AddProductDetails
