import { gql } from '@apollo/client'

export const ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT_FRAGMENT = gql`
  fragment OrganizationWarrantyTypeAssignmentFragment on OrganizationWarrantyTypeAssignment {
    id
    term
    tier
    warrantyType {
      id
    }
  }
`
