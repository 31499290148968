import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { format } from 'date-fns'
import {
  CREATE_SKU,
  ADD_SKU_TO_STANDARD_CATALOG,
} from '@firstbase/data/SKU/mutations'
import {
  createSku,
  createSkuVariables,
} from '@firstbase/data/SKU/__generated__/createSku'
import {
  addItemToStandardCatalog,
  addItemToStandardCatalogVariables,
} from '@firstbase/data/SKU/__generated__/addItemToStandardCatalog'
import { createSkuDefaultValues } from '@firstbase/constants/createSkuDefaultValues'
import { GET_ALL_SKUS } from '@firstbase/data/SKU/queries'
import { getAllSKUsVariables } from '@firstbase/data/SKU/__generated__/getAllSKUs'

import { WarrantySkuForm } from './WarrantySkuForm'
import { ReducerState } from './types'

export const CreateWarrantySku = () => {
  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()
  const closeModal = () => history.replace('/platform/warranties')

  const refetchVariables: getAllSKUsVariables = {
    pageNumber: 1,
    pageSize: 50,
    filter: { categories: ['WARRANTY'] },
  }
  const [
    addItemToStandardCatalogMutation,
    { loading: addItemToStandardCatalogLoading },
  ] = useMutation<addItemToStandardCatalog, addItemToStandardCatalogVariables>(
    ADD_SKU_TO_STANDARD_CATALOG,
    {
      onCompleted: () => {
        enqueueSnackbar('Warranty created successfully', { variant: 'success' })
        closeModal()
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error)
        enqueueSnackbar(
          'Error adding warranty to standard catalog. Please check the console and share with the team.',
          { variant: 'error' }
        )
      },
      refetchQueries: [{ query: GET_ALL_SKUS, variables: refetchVariables }],
    }
  )

  const [createSkuMutation, { loading: createSkuLoading }] = useMutation<
    createSku,
    createSkuVariables
  >(CREATE_SKU, {
    onCompleted: (data) => {
      addItemToStandardCatalogMutation({
        variables: {
          id: data.createSKU.id,
        },
      })
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      enqueueSnackbar(
        'Error creating warranty. Please check the console and share with the team.',
        { variant: 'error' }
      )
    },
  })

  // Copy of what is in add global product
  const generateVendorSku = () => {
    const currentDate = new Date()
    const formattedDate = format(currentDate, 'yyyyMMdd')
    const random4DigitNumber = Math.floor(1000 + Math.random() * 9000)

    return `UNKNOWN-${formattedDate}-${random4DigitNumber}`
  }

  const handleSubmit = (state: ReducerState) => {
    const metdataFieldsArray = Object.entries(state.metadataFields).map(
      ([key, { value }]) => ({
        fieldId: key,
        value,
      })
    )

    createSkuMutation({
      variables: {
        createSku: {
          ...createSkuDefaultValues,
          productTitle: metdataFieldsArray
            .map((field) => field.value)
            .join(' '),
          genericCategory: false,
          categoryCode: 'WARRANTY',
          vendorCode: state.vendor.code,
          skuImages: [state.imageData.url],
          vendorSku: state.mpn || generateVendorSku(),
          partNumber: state.mpn || null,
          standardPricing: state.pricingDetails.rows,
          metadata: metdataFieldsArray,
        },
      },
    })
  }

  return (
    <WarrantySkuForm
      closeModal={closeModal}
      initialState={{
        category: '',
        vendor: { name: '', code: '' },
        metadataFields: {},
        imageData: {
          url: '',
          isValid: true,
        },
        mpn: '',
        pricingDetails: {
          regionCodeSelect: '',
          currencyCodeSelect: '',
          rows: [],
        },
      }}
      handleSubmit={handleSubmit}
      submitting={createSkuLoading || addItemToStandardCatalogLoading}
    />
  )
}
