import { useQuery } from '@apollo/client'
import Table from '@firstbase/components/atoms/Table'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import { GET_PERSON_ALL_ORDERS_BY_ORGANIZATION_SLUG_AND_PERSON_SLUG } from '@firstbase/data/Person/queries'
import {
  getPersonOrdersBySlugAndOrganizationSlug,
  getPersonOrdersBySlugAndOrganizationSlugVariables,
  getPersonOrdersBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug_orders as OrderI,
} from '@firstbase/data/Person/__generated__/getPersonOrdersBySlugAndOrganizationSlug'
import { sentenceCase } from 'change-case'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Page from '@firstbase/components/atoms/Page'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import { getOrderStatusColor } from '@atoms/StatusChip/getColors'

import { ReactComponent as NoOrders } from '@firstbase/assets/NoOrders.svg'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { PersonPageParams } from '@firstbase/types/Pages'

type OwnProps = {
  clientSlug: string
  personName: string
}

const columns: (queryParams: PersonPageParams) => ColumnI[] = (queryParams) => [
  {
    header: 'Submitted Date',
    id: 'createdAt',
    cell: {
      value: ({ createdAt }) => new Date(createdAt).toLocaleDateString(),
    },
  },
  {
    header: 'Order Type',
    id: 'type',
    cell: { value: ({ orderType }) => sentenceCase(orderType) },
  },
  {
    header: 'Order ID',
    id: 'id',
    cell: {
      as: cellComponents.Link,
      asProps: ({ id }) => ({
        to: interpolateQueryParamsInPath('orderDetail', {
          ...queryParams,
          orderId: id,
        }),
      }),
      value: ({ id }) => id,
    },
  },
  {
    header: 'Order Status',
    id: 'status',
    cell: {
      asProps: ({ orderStatus }) => ({
        color: getOrderStatusColor(orderStatus),
      }),
      as: cellComponents.Chip,
      value: ({ orderStatus }) => orderStatus,
    },
  },
]

const sortByMostRecent = (
  { createdAt: createdAtA }: OrderI,
  { createdAt: createdAtB }: OrderI
) => new Date(createdAtB).getTime() - new Date(createdAtA).getTime()

const Orders = ({ clientSlug, personName }: OwnProps) => {
  const { personSlug, clientId }: PersonPageParams = useParams()
  const { data, loading } = useQuery<
    getPersonOrdersBySlugAndOrganizationSlug,
    getPersonOrdersBySlugAndOrganizationSlugVariables
  >(GET_PERSON_ALL_ORDERS_BY_ORGANIZATION_SLUG_AND_PERSON_SLUG, {
    variables: {
      slug: personSlug,
      organizationSlug: clientSlug,
    },
  })
  const person = data?.getPersonBySlugAndOrganizationSlug

  const tableData = useMemo(
    () =>
      loading
        ? []
        : [
            ...(person?.orders || []),
            ...(person?.replacements.map(
              (replacement) =>
                ({
                  ...replacement,
                  orderType: 'REPLACEMENT',
                  orderStatus: replacement.status,
                } as unknown as OrderI)
            ) || []),
          ].sort(sortByMostRecent),
    [loading, person?.orders, person?.replacements]
  )

  const renderNoOrders = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
        gap: 0.5,
      }}
    >
      <NoOrders height={240} width={240} fill-opacity={1} />
      <Typography variant="h5" sx={{ mt: 0.5 }}>
        No orders yet
      </Typography>
      <Typography>
        Once an order is placed for {personName}, you’ll see it here
      </Typography>
    </Box>
  )

  return (
    <>
      <Page.TitleSection sectionTitle="Orders" />
      {!loading && !tableData.length ? (
        renderNoOrders()
      ) : (
        <Table
          tableId="person-orders"
          columns={columns({ clientId, personSlug })}
          query={{
            isLoading: loading,
            data: {
              data: tableData,
            },
          }}
        />
      )}
    </>
  )
}

export default Orders
