import React from 'react'
import { sentenceCase } from 'change-case'
import { IconButton, Box } from '@mui/material'

import { ReactComponent as PencilEditIcon } from '@firstbase/assets/PencilEdit.svg'
import { getAllSKUs_getAllSKUs_data } from '@firstbase/data/SKU/__generated__/getAllSKUs'
import {
  ColumnI,
  SortDirections,
} from '@firstbase/components/atoms/Table/types'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { getSKUStatusColor } from '@firstbase/components/atoms/StatusChip/getColors'

export const warrantiesColumns = (
  canEditProduct: boolean,
  handleEditProduct: (product: getAllSKUs_getAllSKUs_data) => void
) =>
  [
    {
      header: 'Title',
      id: 'skuInformation.productTitle',
      defaultSort: SortDirections.asc,
      cell: {
        value: ({ skuInformation, skuImages }: getAllSKUs_getAllSKUs_data) => (
          <Box display="flex" alignItems="center" gap={1}>
            {skuImages?.length ? (
              <img style={{ width: '100px' }} src={skuImages[0]} />
            ) : null}
            {skuInformation.productTitle}
          </Box>
        ),
        sx: {
          wordBreak: 'break-word',
          whiteSpace: 'normal',
        },
      },
    },
    {
      header: 'MPN (vendorSKU)',
      id: 'vendorSku',
      cell: {
        value: ({ vendorSku }: getAllSKUs_getAllSKUs_data) => vendorSku,
      },
    },
    {
      header: 'Warranty ID',
      id: 'warrantyId',
      cell: {
        as: cellComponents.ID,
        value: ({ id }: getAllSKUs_getAllSKUs_data) => id,
      },
    },
    {
      header: 'SKU Status',
      id: 'skuStatus',
      cell: {
        as: cellComponents.Chip,
        asProps: ({ status }: getAllSKUs_getAllSKUs_data) => ({
          color: getSKUStatusColor(status),
        }),
        value: ({ status }: getAllSKUs_getAllSKUs_data) => sentenceCase(status),
      },
    },
    ...(canEditProduct
      ? [
          {
            id: 'editAction',
            sticky: 'end',
            cell: {
              value: (product: getAllSKUs_getAllSKUs_data) => (
                <IconButton onClick={() => handleEditProduct(product)}>
                  <PencilEditIcon width="1.25rem" height="1.25rem" />
                </IconButton>
              ),
            },
          },
        ]
      : []),
  ] as ColumnI[]
