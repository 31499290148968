import { getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues } from '@firstbase/data/Warranties'
import { SkuMetadataFieldName } from '@globalTypes'

import { ReducerActions, ReducerState } from './types'

export const reducer = (
  state: ReducerState,
  action: ReducerActions
): ReducerState => {
  switch (action.type) {
    case 'SET_CATEGORY':
      return {
        ...state,
        // reset metadata fields when category changes
        metadataFields: {},
        category: action.payload,
      }
    case 'SET_VENDOR':
      return {
        ...state,
        // reset metadata fields when vendor changes
        metadataFields: {},
        vendor: action.payload,
      }
    case 'SET_METADATA_FIELD':
      return {
        ...state,
        metadataFields: {
          ...state.metadataFields,
          [action.payload.fieldId]: {
            value: action.payload.value,
            name: action.payload.name,
          },
        },
      }
    case 'SET_IMAGE_DATA':
      return {
        ...state,
        imageData: {
          ...state.imageData,
          ...action.payload,
        },
      }
    case 'SET_MPN':
      return {
        ...state,
        mpn: action.payload,
      }
    case 'SET_PRICING_DETAILS_CURRENCY_CODE':
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          currencyCodeSelect: action.payload,
        },
      }
    case 'SET_PRICING_DETAILS_REGION_CODE':
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          regionCodeSelect: action.payload,
        },
      }
    case 'ADD_PRICING_DETAILS_ROW':
      return {
        ...state,
        pricingDetails: {
          regionCodeSelect: '',
          currencyCodeSelect: '',
          rows: [...state.pricingDetails.rows, action.payload],
        },
      }
    case 'REMOVE_PRICING_DETAILS_ROW':
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          rows: state.pricingDetails.rows.filter(
            (_, index) => index !== action.payload.index
          ),
        },
      }
    case 'SET_PRICING_DETAILS_PRICE':
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          rows: state.pricingDetails.rows.map((row, index) =>
            index === action.payload.index
              ? { ...row, purchasePrice: action.payload.purchasePrice }
              : row
          ),
        },
      }
    default:
      return state
  }
}

export const groupMetadataFieldsByGroup = (
  items: getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues[]
) => {
  const grouped = items.reduce((acc, item) => {
    if (!acc[item.group]) {
      acc[item.group] = []
    }
    acc[item.group].push(item)
    return acc
  }, {} as Record<number, getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues[]>)

  return Object.keys(grouped)
    .sort((a, b) => Number(a) - Number(b))
    .map((group) => grouped[Number(group)])
}

export const getWarrantySkuTitle = (
  metadata: ReducerState['metadataFields']
): string => {
  const warrantyFields: Record<string, string> = {
    [SkuMetadataFieldName.WARRANTY_TYPE]: '',
    [SkuMetadataFieldName.WARRANTY_TERM]: '',
    [SkuMetadataFieldName.WARRANTY_TIER]: '',
    [SkuMetadataFieldName.WARRANTY_REGION]: '',
  }

  for (const key in metadata) {
    if (metadata[key].name in warrantyFields) {
      const { value, name } = metadata[key]
      warrantyFields[name] = value
    }
  }

  return [
    warrantyFields[SkuMetadataFieldName.WARRANTY_TYPE],
    warrantyFields[SkuMetadataFieldName.WARRANTY_TERM],
    warrantyFields[SkuMetadataFieldName.WARRANTY_TIER],
    warrantyFields[SkuMetadataFieldName.WARRANTY_REGION],
  ]
    .filter(Boolean)
    .join(' ')
}
