import React, { useState } from 'react'
import { Button, Box } from '@mui/material'
import { useHistory } from 'react-router-dom'

import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import {
  getAllSKUs as getAllSKUsType,
  getAllSKUsVariables,
  getAllSKUs_getAllSKUs_data,
} from '@firstbase/data/SKU/__generated__/getAllSKUs'
import { GET_ALL_SKUS } from '@firstbase/data/SKU/queries'
import Page from '@firstbase/components/atoms/Page'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import SearchBox from '@firstbase/components/atoms/SearchBox'
import FloatingContainer from '@firstbase/components/atoms/FloatingContainer'
import { Role, useAuthState } from '@firstbase/context/auth/AuthProvider'
import Table from '@firstbase/components/atoms/Table'

import { warrantiesColumns } from './warrantiesColumns'

export const Warranties = () => {
  const history = useHistory()
  const { hasRole } = useAuthState()
  const [searchTerm, setSearchTerm] = useState('')

  const queryVariables: Pick<getAllSKUsVariables, 'searchTerm' | 'filter'> = {
    searchTerm,
    filter: { categories: ['WARRANTY'] },
  }
  const query = useTableGraphqlQuery<getAllSKUsType, getAllSKUsVariables>(
    GET_ALL_SKUS,
    { variables: queryVariables as getAllSKUsVariables },
    ({ getAllSKUs }) => getAllSKUs
  )

  const canEditProduct = hasRole(Role.Admin)

  const handleEditProduct = (product: getAllSKUs_getAllSKUs_data) => {
    history.push(`/platform/warranties/sku/edit/${product.id}`)
  }

  const renderSubtitleActions = () => (
    <ProtectedChildren hasRole={[Role.Admin]}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.push('/platform/warranties/metadata/config')}
        >
          Configure Warranty Metadata
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.push('/platform/warranties/types/new')}
        >
          Add Warranty Type
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/platform/warranties/sku/new')}
        >
          Add Warranty SKU
        </Button>
      </Box>
    </ProtectedChildren>
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Warranties"
        sectionSubtitle="List of warranties that are available in the platform"
        subtitleAction={renderSubtitleActions}
      />
      <SearchBox
        placeholder="Search warranties"
        label="Search warranties"
        onChange={setSearchTerm}
        disabled={(query.isLoading && !query.isPreviousData) || !!query.error}
      />
      <FloatingContainer>
        <Table
          defaultSortId="skuInformation.productTitle"
          query={{ ...query, variables: queryVariables }}
          tableId="warranties"
          columns={warrantiesColumns(canEditProduct, handleEditProduct)}
          pagination
        />
      </FloatingContainer>
    </>
  )
}
