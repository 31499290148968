import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Table from '@atoms/Table'
import { generateColumns } from './productsTableColumns'
import useLazyQuery from '@firstbase/hooks/useLazyQuery'
import { PageQueryParams } from '@firstbase/components/atoms/Table/Table'
import tableRequest from '@firstbase/components/atoms/Table/tableRequest'
import { QueryOptions } from 'react-query'
import SearchBox from '@firstbase/components/atoms/SearchBox/SearchBox'
import { Box } from '@mui/material'
import { Product } from '@firstbase/types/Product'
import { ClientPageParams } from '@firstbase/types/Pages'

function ProductsTable() {
  const params: ClientPageParams = useParams()
  const [productNameFilter, setProductNameFilter] = useState('')
  const [vendorSkuFilter, setVendorSkuFilter] = useState('')
  const query = useLazyQuery<
    PageQueryParams,
    { data: Product[]; totalElements: number }
  >(
    [
      'products table',
      {
        clientId: params.clientId,
        productNameFilter,
        vendorSkuFilter,
      },
    ],
    ({
      pageIndex,
      rowsPerPage,
      activeSortId,
      activeSortDirection,
    }: PageQueryParams) =>
      tableRequest(`/organizations/${params.clientId}/products`, {
        page: pageIndex + 1,
        rowsPerPage,
        activeSortId,
        activeSortDirection,
        productNameFilter,
        vendorSkuFilter,
      }),
    {
      keepPreviousData: true,
    } as QueryOptions
  )

  const handleProductNameChange = (newSearch: string) =>
    setProductNameFilter(newSearch)

  const handleVendorSkuChange = (newVendorSku: string) =>
    setVendorSkuFilter(newVendorSku)

  return (
    <Box sx={{ pt: 1 }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <SearchBox
          placeholder="Product name"
          label="Search products"
          onChange={handleProductNameChange}
          disabled={query.error || (query.isLoading && !query.isPreviousData)}
        />
        <SearchBox
          placeholder="Vendor sku (MPN)"
          label="Search by mpn"
          onChange={handleVendorSkuChange}
          disabled={query.error || (query.isLoading && !query.isPreviousData)}
        />
      </Box>
      <Table
        tableId="products table"
        pagination
        query={{ ...query, variables: { productNameFilter } }}
        columns={generateColumns(params)}
        customRowsPerPage={100}
        customRowsPerPageOptions={[100, 100, 200]}
      />
    </Box>
  )
}

export default ProductsTable
