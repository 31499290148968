import React, { useEffect } from 'react'
import { Typography, Stack, Box } from '@mui/material'

import { SkuProductCard } from '@firstbase/components/molecules/SkuProductCard'
import { FilteredSkuCardList } from '@firstbase/components/molecules/FilteredSkuCardList'

import { CommonProps } from './types'
import { getWarrantySkuTitle } from './utils'
import { useFilteredSkuListData } from './useFilteredSkuListData'

interface OwnProps {
  setMatchingSkusFound: React.Dispatch<React.SetStateAction<boolean>>
  state: CommonProps['state']
}

export const ExistingSkusPane = ({ setMatchingSkusFound, state }: OwnProps) => {
  const {
    filteredSkus,
    filteredSKUsError,
    filteredSKUsLoading,
    handleLoadMore,
    hasMore,
    totalElements,
  } = useFilteredSkuListData({
    metadataFields: state.metadataFields,
    vendorCode: state.vendor.code,
  })

  useEffect(() => {
    setMatchingSkusFound(filteredSkus.length > 0)
  }, [filteredSkus.length])

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          Add warranty as:
        </Typography>
        <SkuProductCard
          image={state.imageData.url}
          vendor={state.vendor.name}
          skuTitle={getWarrantySkuTitle(state.metadataFields)}
          skuChips={[]}
        />
      </Box>
      <Box>
        <Typography variant="body1">
          Existing warranties in platform cataglog ({totalElements})
        </Typography>
        <FilteredSkuCardList
          filteredSkus={filteredSkus}
          filteredSKUsError={filteredSKUsError}
          filteredSKUsLoading={filteredSKUsLoading}
          handleLoadMore={handleLoadMore}
          hasMore={hasMore}
          getSkuTitle={(sku) => sku.skuInformation.productTitle}
        />
      </Box>
    </Stack>
  )
}
