import React, { useCallback } from 'react'
import { QueryOptions, useQuery } from 'react-query'

type ReturnType<T, Response> = {
  isLoading: boolean
  error: any
  isPreviousData?: boolean
  data: Response
  fetch: (params: T) => Promise<{
    isLoading: boolean
    error?: any
    data: Response
    isPreviousData: boolean
  }>
}

/**
 *
 * an apollo/client-eqsue hook for react-query
 */
const useLazyQuery = <FetchParams, Response>(
  requestId: any,
  request: (arg: FetchParams) => void,
  options?: QueryOptions
) => {
  const [variables, setVariables] = React.useState({} as unknown as FetchParams)
  const enabled = !!Object.keys(variables).length
  const requestKey = Array.isArray(requestId) ? requestId : [requestId]
  const { refetch: _refetch, ...rest } = useQuery(
    [...requestKey, variables],
    () => request(variables),
    {
      ...options,
      enabled,
    }
  )

  const fetch = useCallback((vars: any) => setVariables(vars), [])

  return {
    ...rest,
    fetch,
  } as unknown as ReturnType<FetchParams, Response>
}

export default useLazyQuery
