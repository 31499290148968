import React from 'react'
import { SortDirections } from '@firstbase/components/atoms/Table'

import { PricingRow } from './Product'
import { Box, InputAdornment, TextField } from '@mui/material'
import { formatTimestamp, timestampAsLongDate } from '@utils/datetime/format'
import BillTypeSelect from '@views/Home/Product/BillTypeSelect'

const defaultColumns = [
  {
    header: 'Supported Region',
    defaultSort: SortDirections.asc,
    id: 'countryCode',
    cell: {
      value: ({ countryCode }: PricingRow) => <>{countryCode}</>,
    },
  },
  {
    header: 'Charged Currency',
    defaultSort: SortDirections.asc,
    id: 'currency',
    cell: {
      value: ({ currency }: PricingRow) => <>{currency}</>,
    },
  },
]

const priceColumn = {
  header: 'Price',
  defaultSort: SortDirections.asc,
  id: 'price',
}

const typeColumn = {
  header: 'Billing Type',
  defaultSort: SortDirections.asc,
  id: 'billingType',
  sx: {
    minWidth: '300px',
  },
}

const whenColumns = [
  {
    header: 'Start Date',
    defaultSort: SortDirections.asc,
    id: 'startDate',
    cell: {
      value: ({ startDate }: PricingRow) => (
        <>{timestampAsLongDate(startDate, true)}</>
      ),
    },
  },
  {
    header: 'End Date',
    defaultSort: SortDirections.asc,
    id: 'endDate',
    cell: {
      value: ({ endDate }: PricingRow) => (
        <>{timestampAsLongDate(endDate, true)}</>
      ),
    },
  },
  {
    header: 'Last Modified',
    defaultSort: SortDirections.asc,
    id: 'lastModified',
    cell: {
      value: ({ lastModified }: PricingRow) => (
        <>{formatTimestamp(lastModified)}</>
      ),
    },
  },
]

// TODO: SE-2312 use codebase defined constants for currencies
export const viewPricingTableColumns = [
  ...defaultColumns,
  {
    ...priceColumn,
    cell: {
      value: ({ currency, price }: PricingRow) => (
        <>
          {price.toLocaleString(undefined, {
            style: 'currency',
            currency,
          })}
        </>
      ),
    },
  },
  {
    ...typeColumn,
    cell: {
      value: ({ billingType }: PricingRow) => <>{billingType}</>,
    },
  },
  ...whenColumns,
]

export const editPricingTableColumns = (
  handlePriceChange: (
    pricingRow: PricingRow,
    validate?: boolean
  ) => (e: any) => void,
  handleBillTypeChange: (pricingRow: PricingRow) => (e: any) => void,
  allowSubscription: boolean,
  disableEditing?: boolean
) => [
  ...defaultColumns,
  {
    ...priceColumn,
    cell: {
      value: (pricingRow: PricingRow) => (
        <Box>
          <TextField
            InputProps={{
              startAdornment: (
                // TODO: SE-2312 use codebase defined constants for currencies
                <InputAdornment position="start">
                  {(0)
                    .toLocaleString(undefined, {
                      style: 'currency',
                      currency: pricingRow.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                    .replace(/\d/g, '')
                    .trim()}
                </InputAdornment>
              ),
              inputProps: { min: 0, onWheel: (e) => e.currentTarget.blur() },
            }}
            id="outlined-helperText"
            label="Price"
            type="number"
            size="small"
            value={pricingRow.price}
            onFocus={(event) => {
              event.target.select()
            }}
            onChange={handlePriceChange(pricingRow)}
            onBlur={handlePriceChange(pricingRow, true)}
            placeholder="0"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disableEditing}
          />
        </Box>
      ),
    },
  },
  {
    ...typeColumn,
    cell: {
      value: (pricingRow: PricingRow) => (
        <BillTypeSelect
          onChange={handleBillTypeChange(pricingRow)}
          defaultValue={pricingRow.billingType}
          allowSubscription={allowSubscription}
        />
      ),
    },
  },
]
