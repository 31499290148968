export const createSkuDefaultValues = {
  heightUnit: 'INCH',
  heightValue: 0,
  widthUnit: 'INCH',
  widthValue: 0,
  lengthUnit: 'INCH',
  lengthValue: 0,
  currency: 'USD',
  costPrice: 0,
  numberOfBoxes: 1,
  boxes: [
    {
      heightUnit: 'INCH',
      heightValue: 0,
      widthUnit: 'INCH',
      widthValue: 0,
      lengthUnit: 'INCH',
      lengthValue: 0,
      weightUnit: 'KILOGRAM',
      weightValue: 0,
    },
  ],
  availableCountries: ['US'],
}
