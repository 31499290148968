import React from 'react'
import { Alert, Typography } from '@mui/material'
import { useQueries } from 'react-query'
import { useParams } from 'react-router-dom'

import { ContractResponse } from '@firstbase/types/Contract'
import request from '@firstbase/utils/request'
import CurrenciesAccordion from './CurrenciesAccordion'
import FeesAccordion from './FeesAccordion'
import { ClientResponse } from '@firstbase/types/Client'
import { ClientPageParams } from '@firstbase/types/Pages'

const PricingSettings = () => {
  const { clientId }: ClientPageParams = useParams()
  const [
    { data: contractResponse, isLoading: isContractLoading },
    { data: orgResponse, isLoading: isOrgLoading },
  ] = useQueries([
    {
      queryKey: ['client contract', { clientId }],
      queryFn: () =>
        request<ContractResponse>({
          url: `/organizations/${clientId}/contract`,
        }),
    },
    {
      queryKey: ['client', { clientId }],
      queryFn: () =>
        request<ClientResponse>({ url: `/organizations/${clientId}` }),
    },
  ])

  // presume has fees whilst loading
  const hasFees =
    isContractLoading || isOrgLoading || !!contractResponse?.fees.length

  return (
    <>
      <Typography variant="h6">Pricing</Typography>
      <CurrenciesAccordion
        isContractLoading={isContractLoading}
        contractResponse={contractResponse}
      />
      {hasFees ? (
        <>
          <FeesAccordion
            title="Management Fees"
            isContractLoading={isContractLoading}
            fees={contractResponse?.fees.filter(
              ({ billingType }) => billingType === 'Subscription'
            )}
            currencies={contractResponse?.currencies}
          />
          <FeesAccordion
            title="Service Fees"
            isContractLoading={isContractLoading}
            fees={contractResponse?.fees.filter(
              ({ billingType }) => billingType === 'OnDemand'
            )}
            currencies={contractResponse?.currencies}
          />
        </>
      ) : (
        <Alert severity="info" sx={{ my: 2 }}>
          Fees for {orgResponse?.name} are managed off-platform
        </Alert>
      )}
    </>
  )
}

export default PricingSettings
