import React, { useState, useEffect, useMemo, Children, Fragment } from 'react'
import { Box, Tab, TabProps, Tabs, TabsProps } from '@mui/material'

import TabPage from './TabPage'
import { SetTabSelectorType, TabGroupContext } from './TabGroupContext'
import { useHistory } from 'react-router-dom'
import useQueryParams from '@firstbase/hooks/useQueryParams'

const removeInvalidChildren: (
  children: React.ReactNode
) => (React.ReactNode | null)[] = (children) =>
  Array(
    Children.map(children, (child) => {
      if (!child || typeof child !== 'object') return null
      const { type, props } = child as React.ReactElement

      // if fragment has children, then flatten and loop through children
      if (type == Fragment && props.children)
        return removeInvalidChildren(props.children)

      return type == TabPage ? child : null
    })
  )

type TabGroupProps = {
  children: React.ReactNode
  defaultTabId?: string
  queryParam?: boolean
}

type TabsType = {
  [tabId: string]: Partial<TabProps>
}
const TabGroup = ({
  children,
  defaultTabId,
  queryParam,
  ...others
}: TabGroupProps & TabsProps) => {
  const query = useQueryParams()
  const [activeTabId, setActiveTabId] = useState(
    () => (queryParam && query.get('tab')) || defaultTabId
  )
  const history = useHistory()
  const [tabs, setTabs] = useState<TabsType>({})

  const handleChangeActiveTabId = (
    e: React.SyntheticEvent,
    activeTabValue: string
  ) => setActiveTabId(activeTabValue)

  const setTabSelector: SetTabSelectorType = (tabId, tabProps) =>
    setTabs((prevTabs) => ({ ...prevTabs, [tabId]: tabProps }))

  const contextValue = useMemo(
    () => ({ activeTabId, setTabSelector }),
    [activeTabId]
  )

  // update the query param on tab change
  useEffect(() => {
    if (queryParam && activeTabId)
      history.replace({ search: `?tab=${activeTabId}` })
  }, [activeTabId, history, queryParam])

  // if no defaultTabId then activeTabId should be first tab
  useEffect(() => {
    if (tabs && !activeTabId) setActiveTabId(Object.keys(tabs)[0])
  }, [activeTabId, tabs])

  // only allow children that are TabPages to render
  const validTabPages = removeInvalidChildren(
    Array.isArray(children) ? children : [children]
  ).flat()

  if (!validTabPages.length) return null

  return (
    <TabGroupContext.Provider value={contextValue}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {activeTabId && (
          <Tabs
            {...others}
            value={activeTabId}
            onChange={handleChangeActiveTabId}
          >
            {Object.entries(tabs).map(([tabId, tabProps]) => (
              <Tab
                {...tabProps}
                key={tabId}
                value={tabId}
                data-testid={`tab-item?id=${tabId}&active=${
                  activeTabId === tabId
                }`}
              />
            ))}
          </Tabs>
        )}
      </Box>
      {validTabPages}
    </TabGroupContext.Provider>
  )
}

export default TabGroup
TabGroup.Page = TabPage
