import React, { useEffect, useState } from 'react'
import { BillingType, ProductResponse } from '@views/Home/Product/Product'
import FullscreenModal from '@molecules/FullscreenModal'
import { useQueries } from 'react-query'
import request from '@utils/request'
import SectionError from '@atoms/SectionError'
import PricingHistoryTable from '@views/Home/Product/PricingHistory/PricingHistoryTable'

export type PricingHistoryData = (PricingHistoryRow & { isDirty?: boolean })[]

export interface PricingHistoryPersonType {
  id: string
  forename: string
  surname: string
  email: string
  slug: string
}

export interface PricingHistoryRow {
  orgId: string
  skuId: string
  id?: string
  countryCode: string
  price: number
  currency: string
  billingType: BillingType
  startDate?: string | null
  createdAt?: string | null
  createdBy: PricingHistoryPersonType
}

export interface PricingHistoryResponse {
  orgId: string
  orgName: string
  skuId: string
  productName: string
  billingFrequency: 'Monthly' | 'Annually'
  prices: PricingHistoryRow[]
  firstbaseSupplied: boolean | null
}

type OwnProps = {
  open: boolean
  handleClose: (flushData?: boolean) => void
  product: ProductResponse
}

function PricingHistory({ open, handleClose, product }: OwnProps) {
  const { orgName, productName, orgId: clientId, skuId: productId } = product
  const [historyPrices, setHistoryPrices] = useState<PricingHistoryRow[]>([])
  const [{ data: pricingHistoryData, isLoading: isLoading, error }] =
    useQueries([
      {
        queryKey: ['product pricing history', { clientId, productId }],
        queryFn: () =>
          request<PricingHistoryResponse>({
            url: `/organizations/${clientId}/products/${productId}/pricing/history`,
          }),
      },
    ])
  const showViewHistoryPriceRows = pricingHistoryData?.prices.length

  useEffect(() => {
    if (open && pricingHistoryData?.prices.length) {
      setHistoryPrices(() => {
        return pricingHistoryData.prices
          .map(
            ({
              countryCode: orgCountryCode,
              currency: orgCurrency,
              price: price,
              startDate: startDate,
              createdAt: createdAt,
              createdBy: createdBy,
              billingType: billingType,
            }) => {
              return {
                orgId: pricingHistoryData.orgId,
                skuId: pricingHistoryData.skuId,
                countryCode: orgCountryCode,
                currency: orgCurrency,
                billingType: billingType,
                price: price,
                startDate: startDate,
                createdAt: createdAt,
                createdBy: createdBy,
              } as PricingHistoryRow
            }
          )
          .filter((el) => el)
      })
    }
    if (!open) {
      setHistoryPrices(() => [])
    }
  }, [open, pricingHistoryData])

  return (
    <FullscreenModal
      open={open}
      handleClose={handleClose}
      closeDisabled={false}
      title={`${orgName} pricing history for ${productName}`}
    >
      {error && !pricingHistoryData && (
        <SectionError
          title="Error fetching pricing history information"
          body="Please ensure client settings have been created and has at least one valid currency. If this problem persists, please contact engineering"
          sx={{ marginBottom: '24px' }}
        />
      )}
      <PricingHistoryTable
        pricingHistoryData={showViewHistoryPriceRows ? historyPrices : []}
        isLoading={isLoading}
      />
    </FullscreenModal>
  )
}
//TODO chavez fix row size
//TODO chavez change modal to something without "cancel", "continue"
export default PricingHistory
