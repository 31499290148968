import React, { useState } from 'react'

import Table from '@atoms/Table'
import { PricingHistoryRow } from './PricingHistory'
import { viewPricingHistoryTableColumns } from './pricingHistoryTableColumns'
import { PageQueryParams } from '@firstbase/components/atoms/Table/Table'
import { getComparator } from '@firstbase/utils/tableUtils'
import { ColumnI } from '@atoms/Table/types'

type OwnProps = {
  pricingHistoryData: PricingHistoryRow[]
  isLoading: boolean
}

function PricingHistoryTable({
  pricingHistoryData = [],
  isLoading = false,
}: OwnProps) {
  const [pricesHistory, setPricingHistory] =
    useState<PricingHistoryRow[]>(pricingHistoryData)

  const fetch = ({ activeSortId, activeSortDirection }: PageQueryParams) => {
    if (!activeSortId) return null

    setPricingHistory(
      pricesHistory.sort(
        getComparator(
          activeSortDirection,
          activeSortId.replace('History', '') || ''
        )
      )
    )
  }

  return (
    <Table
      noDataMessage="No historical prices available"
      dataKey="countryCodeHistory"
      tableId="pricing history table"
      query={{ fetch, isLoading, data: { data: pricesHistory } }}
      columns={viewPricingHistoryTableColumns as ColumnI[]}
    />
  )
}

export default PricingHistoryTable
