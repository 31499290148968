import { useQuery } from '@apollo/client'
import {
  getContractsByOrg as GetContractsByOrg,
  getContractsByOrgVariables,
} from '@firstbase/data/ProductTier/__generated__/GetContractsByOrg'
import { GET_CONTRACTS_BY_ORGANIZATION } from '@firstbase/data/ProductTier/contractQueries'
import { useMemo } from 'react'

export const useGetOrganizationContract = (orgId?: string) => {
  const contractsQuery = useQuery<
    GetContractsByOrg,
    getContractsByOrgVariables
  >(GET_CONTRACTS_BY_ORGANIZATION, {
    variables: {
      orgId: orgId ?? '',
    },
    skip: !orgId,
  })

  const contractProductTiers = useMemo(() => {
    const productTiers =
      contractsQuery.data?.getContractsByOrg.contracts?.[0]?.productTiers

    if (!productTiers || productTiers.length === 0) {
      return []
    }

    return productTiers
  }, [contractsQuery.data])

  return {
    contracts: contractsQuery.data?.getContractsByOrg.contracts,
    contractProductTiers,
    ...contractsQuery,
  }
}
