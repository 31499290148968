import { gql } from '@apollo/client'

import { ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT_FRAGMENT } from './fragments'

export const GET_ORGANIZATION_WARRANTIES = gql`
  ${ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT_FRAGMENT}
  query getOrganizationWarranties($organizationId: ID!) {
    getWarrantyTypes {
      id
      name
      active
      vendorName
      vendorId
      options {
        terms
        tiers
      }
    }
    getOrganizationWarrantyTypeAssignments(organizationId: $organizationId) {
      ...OrganizationWarrantyTypeAssignmentFragment
    }
  }
`

export const GET_AVAILABLE_WARRANTY_METADATA_FIELDS = gql`
  query getAvailableWarrantyMetadataFields {
    getAvailableFieldNames
  }
`

export const GET_WARRANTY_METADATA_CONFIG_FOR_CATEGORY = gql`
  query getWarrantyMetadataConfigForCategory($categoryCode: String!) {
    getConfigurationsForCategoryCode(categoryCode: $categoryCode) {
      id
      fieldName
    }
  }
`

export const GET_ALL_WARRANTY_SKU_METADATA_OPTION_VALUES = gql`
  query getAllWarrantySKUMetadataOptionValues(
    $categoryCode: String!
    $vendorCode: String!
  ) {
    getAllWarrantySKUMetadataOptionValues(
      categoryCode: $categoryCode
      vendorCode: $vendorCode
    ) {
      id
      name
      hasSpecificVendorValues
      required
      group
      values {
        id
        value
      }
    }
  }
`
