/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ConjunctiveOperator {
  AND = "AND",
  OR = "OR",
}

export enum DeployReason {
  ASSIGNED_TO_ADMIN = "ASSIGNED_TO_ADMIN",
  AWAITING_INFORMATION = "AWAITING_INFORMATION",
  BOUGHT_OUT = "BOUGHT_OUT",
  CANCELLED_BY_FIRSTBASE = "CANCELLED_BY_FIRSTBASE",
  CANCELLED_BY_VENDOR = "CANCELLED_BY_VENDOR",
  CHURNED_CUSTOMER = "CHURNED_CUSTOMER",
  DATA_ERROR = "DATA_ERROR",
  DATA_HOLD = "DATA_HOLD",
  DECOMMISSIONED_BY_EMPLOYER = "DECOMMISSIONED_BY_EMPLOYER",
  DECOMMISSIONED_BY_FIRSTBASE = "DECOMMISSIONED_BY_FIRSTBASE",
  END_OF_USEFUL_LIFE_DESTROYED = "END_OF_USEFUL_LIFE_DESTROYED",
  END_OF_USEFUL_LIFE_DONATED = "END_OF_USEFUL_LIFE_DONATED",
  GIFTED_BY_EMPLOYER = "GIFTED_BY_EMPLOYER",
  GIFTED_BY_FIRSTBASE = "GIFTED_BY_FIRSTBASE",
  LOCKED_APPLE_ACTIVATION = "LOCKED_APPLE_ACTIVATION",
  LOCKED_FMD_REPORTED_LOST_STOLEN = "LOCKED_FMD_REPORTED_LOST_STOLEN",
  LOCKED_MDM = "LOCKED_MDM",
  LOCKED_PIN = "LOCKED_PIN",
  LOST_BY_FIRSTBASE = "LOST_BY_FIRSTBASE",
  LOST_BY_WORKER = "LOST_BY_WORKER",
  LOST_IN_TRANSIT = "LOST_IN_TRANSIT",
  MARKED_FOR_DESTRUCTION = "MARKED_FOR_DESTRUCTION",
  NA = "NA",
  NEVER_RECEIVED_BY_WORKER = "NEVER_RECEIVED_BY_WORKER",
  NEVER_RETURNED_BY_WORKER = "NEVER_RETURNED_BY_WORKER",
  OUT_FOR_DESTRUCTION = "OUT_FOR_DESTRUCTION",
  OUT_FOR_REPAIR = "OUT_FOR_REPAIR",
  PENDING_BUY_OUT = "PENDING_BUY_OUT",
  PENDING_FULFILLMENT = "PENDING_FULFILLMENT",
  PERSONAL_DEVICE = "PERSONAL_DEVICE",
  REQUIRES_CLEANING = "REQUIRES_CLEANING",
  REQUIRES_REPAIR = "REQUIRES_REPAIR",
  RETURN_IN_PROGRESS = "RETURN_IN_PROGRESS",
  SENT_TO_OFFICE = "SENT_TO_OFFICE",
  UNRESPONSIVE_WORKER = "UNRESPONSIVE_WORKER",
  UNSUPPORTED_REGION = "UNSUPPORTED_REGION",
}

export enum DeployStatus {
  ARCHIVED = "ARCHIVED",
  AVAILABLE = "AVAILABLE",
  AWAITING_ARRIVAL = "AWAITING_ARRIVAL",
  DEPLOYED = "DEPLOYED",
  GIFTED = "GIFTED",
  LOCKED = "LOCKED",
  ON_HOLD = "ON_HOLD",
  OUT_FOR_REPAIR = "OUT_FOR_REPAIR",
  UNAVAILABLE = "UNAVAILABLE",
  VOIDED = "VOIDED",
}

export enum EmailNotificationType {
  APPROVAL = "APPROVAL",
  COMPLETED_OFFBOARDING = "COMPLETED_OFFBOARDING",
  ORDER_CONFIRMATION = "ORDER_CONFIRMATION",
  ORDER_SHIPPED = "ORDER_SHIPPED",
  RETURN_DETAILS_CONFIRMED = "RETURN_DETAILS_CONFIRMED",
}

export enum ExpeditedShippingOption {
  EXPEDITED_PREMIUM = "EXPEDITED_PREMIUM",
  EXPEDITED_STANDARD = "EXPEDITED_STANDARD",
}

export enum FeatureStatus {
  ACTIVE = "ACTIVE",
}

export enum GroupName {
  APP_ADMIN = "APP_ADMIN",
  APP_ADMIN_READ_ONLY = "APP_ADMIN_READ_ONLY",
  APP_LEAVER = "APP_LEAVER",
  APP_USER = "APP_USER",
  MGMT_ADMIN = "MGMT_ADMIN",
  MGMT_USER = "MGMT_USER",
}

export enum InventoryConditionStatus {
  NEW = "NEW",
  UNUSABLE = "UNUSABLE",
  USED = "USED",
  USED_FAIR = "USED_FAIR",
  USED_GOOD = "USED_GOOD",
  USED_LIKE_NEW = "USED_LIKE_NEW",
  USED_POOR = "USED_POOR",
}

export enum InventoryOrderStatus {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  ORDERED = "ORDERED",
  PROCESSING = "PROCESSING",
  SHIPPED = "SHIPPED",
}

export enum MergeTenant {
  EU = "EU",
  US = "US",
}

/**
 *  the return statuses are handled in Returns.graphqls file
 */
export enum OrderStatus {
  APPROVED = "APPROVED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  AWAITING_DELIVERY_SCHEDULING = "AWAITING_DELIVERY_SCHEDULING",
  AWAITING_FULFILLMENT = "AWAITING_FULFILLMENT",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  DELIVERED = "DELIVERED",
  DELIVERY_SCHEDULED = "DELIVERY_SCHEDULED",
  NONE = "NONE",
  NOT_COMPLETED = "NOT_COMPLETED",
  NOT_RETURNED = "NOT_RETURNED",
  ORDERED = "ORDERED",
  ORDERED_NOT_SHIPPED = "ORDERED_NOT_SHIPPED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  PARTIALLY_SHIPPED = "PARTIALLY_SHIPPED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  RECEIVED_IN_WAREHOUSE = "RECEIVED_IN_WAREHOUSE",
  RETURNED = "RETURNED",
  RETURN_KIT_DELIVERED = "RETURN_KIT_DELIVERED",
  RETURN_KIT_SENT = "RETURN_KIT_SENT",
  RETURN_KIT_SENT_BACK = "RETURN_KIT_SENT_BACK",
  SHIPPED = "SHIPPED",
  SUBMITTED = "SUBMITTED",
}

export enum OrganizationStatus {
  DEMO = "DEMO",
  INACTIVE = "INACTIVE",
  LIVE = "LIVE",
}

export enum PauseReason {
  CUSTOMER_CANCELLED = "CUSTOMER_CANCELLED",
  PAYMENT_NEEDED = "PAYMENT_NEEDED",
}

export enum PersonStatus {
  ACTIVE = "ACTIVE",
  CANNOT_REACTIVATE = "CANNOT_REACTIVATE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
  INVITED = "INVITED",
  OFFBOARDING = "OFFBOARDING",
}

export enum PreferredCondition {
  NEW = "NEW",
  USED = "USED",
}

export enum ProductPriceBillingType {
  PURCHASED = "PURCHASED",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export enum ProductTierStatus {
  ACTIVE = "ACTIVE",
  NOT_ACTIVE = "NOT_ACTIVE",
}

export enum RegionCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export enum ReplacementStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  PROCESSING = "PROCESSING",
  SUBMITTED = "SUBMITTED",
}

export enum RetrievalMethodMaterialType {
  DIGITAL = "DIGITAL",
  PHYSICAL = "PHYSICAL",
}

export enum RetrievalMethodStatus {
  ARRIVED_AT_PROCESSING_FACILITY = "ARRIVED_AT_PROCESSING_FACILITY",
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED",
  DELIVERY_EXCEPTION = "DELIVERY_EXCEPTION",
  LABEL_CREATED = "LABEL_CREATED",
  NOT_PROCESSED = "NOT_PROCESSED",
  PARTIALLY_PROCESSED = "PARTIALLY_PROCESSED",
  PROCESSED = "PROCESSED",
  REQUESTED = "REQUESTED",
  SENT_BACK = "SENT_BACK",
  SHIPPED = "SHIPPED",
}

export enum ReturnOrderStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  NONE = "NONE",
  NOT_COMPLETED = "NOT_COMPLETED",
  NOT_RETURNED = "NOT_RETURNED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  RECEIVED_IN_WAREHOUSE = "RECEIVED_IN_WAREHOUSE",
  RETURNED = "RETURNED",
  RETURN_KIT_DELIVERED = "RETURN_KIT_DELIVERED",
  RETURN_KIT_SENT = "RETURN_KIT_SENT",
  RETURN_KIT_SENT_BACK = "RETURN_KIT_SENT_BACK",
  SUBMITTED = "SUBMITTED",
}

export enum ReturnReason {
  DAMAGED = "DAMAGED",
  FULFILLMENT_ERROR = "FULFILLMENT_ERROR",
  LOST_BY_PARTNER = "LOST_BY_PARTNER",
  LOST_BY_WORKER = "LOST_BY_WORKER",
  OFFBOARDING = "OFFBOARDING",
  OFF_CYCLE = "OFF_CYCLE",
  OTHER = "OTHER",
  PRODUCT_CHANGE = "PRODUCT_CHANGE",
  STANDARD_LIFECYCLE = "STANDARD_LIFECYCLE",
  UNKNOWN = "UNKNOWN",
  UPGRADE = "UPGRADE",
}

export enum SKUStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum SKUType {
  AUDIO = "AUDIO",
  COMPUTER = "COMPUTER",
  DEFAULT = "DEFAULT",
  DOCKING_STATION = "DOCKING_STATION",
  FURNITURE = "FURNITURE",
  KEYBOARD = "KEYBOARD",
  MONITOR = "MONITOR",
  PERIPHERALS = "PERIPHERALS",
  WARRANTY = "WARRANTY",
  WEBCAM = "WEBCAM",
}

export enum SkuMetadataFieldName {
  BIOMETRIC_TECHNOLOGY = "BIOMETRIC_TECHNOLOGY",
  COLOR = "COLOR",
  CPU_CORES = "CPU_CORES",
  GENERATION = "GENERATION",
  GPU_CORES = "GPU_CORES",
  GRAPHICS_CARD = "GRAPHICS_CARD",
  KEYBOARD_LANGUAGE = "KEYBOARD_LANGUAGE",
  MODEL = "MODEL",
  OPERATING_SYSTEM = "OPERATING_SYSTEM",
  POWER_PLUG_TYPE = "POWER_PLUG_TYPE",
  PROCESSOR = "PROCESSOR",
  PROCESSOR_SPEED = "PROCESSOR_SPEED",
  RAM_CAPACITY = "RAM_CAPACITY",
  SCREEN_SIZE = "SCREEN_SIZE",
  SERIES = "SERIES",
  SSD_CAPACITY = "SSD_CAPACITY",
  TOUCH_SCREEN = "TOUCH_SCREEN",
  WARRANTY_REGION = "WARRANTY_REGION",
  WARRANTY_TERM = "WARRANTY_TERM",
  WARRANTY_TIER = "WARRANTY_TIER",
  WARRANTY_TYPE = "WARRANTY_TYPE",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum StorageType {
  HARD_DISK_DRIVE = "HARD_DISK_DRIVE",
  SOLID_STATE_DRIVE = "SOLID_STATE_DRIVE",
}

export enum SubscriptionInterval {
  MONTHLY = "MONTHLY",
}

export enum SuppliedBy {
  CUSTOMER = "CUSTOMER",
  FIRSTBASE = "FIRSTBASE",
}

export interface AutomaticOrderRequest {
  packageSlug: string;
  expeditionRequests: (ExpeditedAutomaticPackageSku | null)[];
}

export interface CreateAddress {
  addressLine1?: string | null;
  addressLine2?: string | null;
  administrativeArea?: string | null;
  countryCode: string;
  dependentLocality?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  sortingCode?: string | null;
  phoneNumber?: string | null;
}

export interface CreateBox {
  heightUnit: string;
  heightValue: any;
  widthUnit: string;
  widthValue: any;
  lengthUnit: string;
  lengthValue: any;
  weightUnit: string;
  weightValue: any;
}

export interface CreateConnection {
  connector: string;
  maleFemale: any;
  quantity: number;
}

export interface CreateConnectionNoGender {
  connector: string;
  quantity: number;
}

export interface CreateFeatureParams {
  name: string;
  description?: string | null;
  category?: string | null;
}

export interface CreateInventory {
  skuId?: string | null;
  serialNumber?: string | null;
  warehouseId?: string | null;
  personSlug?: string | null;
  officeId?: string | null;
  organizationSlug?: string | null;
  active?: boolean | null;
  description?: string | null;
  conditionStatus?: InventoryConditionStatus | null;
  vendorSku?: string | null;
  vendorCode?: string | null;
  dropship?: boolean | null;
  firstbaseSupplied?: boolean | null;
  createdAt?: any | null;
  renewalDate?: any | null;
  mpnValidated?: boolean | null;
  deployStatus?: DeployStatus | null;
  deployReason?: DeployReason | null;
  returnOrderItemId?: string | null;
  pricingId?: string | null;
}

export interface CreateLeadTime {
  minLeadTime: number;
  maxLeadTime: number;
  region: RegionCode;
}

export interface CreateOrder {
  products: WriteOrderItem[];
  shippingAddress: CreateAddress;
  productPackageSlug?: string | null;
}

export interface CreateOrganizationWarrantyTypeAssignment {
  warrantyTypeId: string;
  organizationId: string;
  term: number;
  tier?: string | null;
}

/**
 *  Forename or surname is required
 */
export interface CreatePerson {
  forename?: string | null;
  surname?: string | null;
  email: string;
  secondaryEmail?: string | null;
  address: CreateAddress;
  startDate?: any | null;
  groups?: (GroupName | null)[] | null;
  packageSlugs?: (string | null)[] | null;
  newHire?: boolean | null;
  automaticOrderRequests?: AutomaticOrderRequest[] | null;
  integrations?: UpdatePersonIntegrations | null;
}

export interface CreateProductTierParams {
  name: string;
  description?: string | null;
  featureIds?: string[] | null;
}

export interface CreateReplacement {
  createOrder: CreateOrder;
  createReturn: CreateReturn;
}

export interface CreateRetrievalMethod {
  inboundCarrierName?: string | null;
  outboundCarrierName?: string | null;
  description?: string | null;
  returnOrderItemIds: string[];
  retrievalType: string;
  inboundRetrievalDetails?: RetrievalDetails | null;
  outboundRetrievalDetails?: RetrievalDetails | null;
  type?: RetrievalMethodMaterialType | null;
}

export interface CreateReturn {
  returnItems: ReturnItem[];
  returnKitAddress: CreateAddress;
}

export interface CreateSKU {
  vendorCode?: string | null;
  vendorSku?: string | null;
  modelNumber?: string | null;
  modelYear?: string | null;
  partNumber?: string | null;
  upc?: string | null;
  ean?: string | null;
  categoryCode: string;
  availableCountries?: string[] | null;
  productTitle?: string | null;
  productLongDescription?: string | null;
  bullet1?: string | null;
  bullet2?: string | null;
  bullet3?: string | null;
  skuImages?: string[] | null;
  heightUnit: string;
  heightValue: any;
  widthUnit: string;
  widthValue: any;
  lengthUnit: string;
  lengthValue: any;
  colors?: (CreateSKUColor | null)[] | null;
  currency: string;
  costPrice: any;
  numberOfBoxes: number;
  boxes?: (CreateBox | null)[] | null;
  dropShipOnly?: boolean | null;
  includedComponents?: string | null;
  screenSizeUnit?: string | null;
  screenSizeValue?: any | null;
  processorVendor?: string | null;
  processorModel?: string | null;
  processorSpeed?: string | null;
  numberOfCores?: number | null;
  ramSize?: number | null;
  storage?: (CreateStorage | null)[] | null;
  graphicsCardVendor?: string | null;
  graphicsCardModel?: string | null;
  ports?: (CreateConnectionNoGender | null)[] | null;
  includedConnectors?: (CreateConnection | null)[] | null;
  compatibleSKUs?: (string | null)[] | null;
  material?: string | null;
  microphoneIncluded?: boolean | null;
  refreshRate?: string | null;
  resolution?: string | null;
  wireless?: boolean | null;
  keyboardLayout?: string | null;
  leadTimes?: (CreateLeadTime | null)[] | null;
  genericCategory?: boolean | null;
  standardPricing?: StandardPricingInput[] | null;
  metadata?: SKUMetadataInput[] | null;
}

export interface CreateSKUAssignment {
  vendorSku: string;
  vendorCode: string;
  organizationSlug: string;
  threshold: number;
  firstbaseSupplied?: boolean | null;
  minimums?: MinimumInventory[] | null;
}

export interface CreateSKUColor {
  name: string;
  description: string;
}

export interface CreateStorage {
  size: number;
  type: StorageType;
}

export interface CreateWarrantyType {
  name: string;
  active: boolean;
  vendorId: string;
}

export interface DateRange {
  begin: any;
  end: any;
}

export interface ExpeditedAutomaticPackageSku {
  skuId: string;
  option: ExpeditedShippingOption;
}

export interface FeatureFilter {
  name?: string | null;
}

export interface InventoryTagFilter {
  conjunctiveOperator: ConjunctiveOperator;
  tags: string[];
}

export interface LocationFilter {
  countryCode: string;
  administrativeArea?: string | null;
}

export interface MinimumInventory {
  warehouseId: string;
  minimumQuantity: number;
  includeUsed: boolean;
}

export interface NewReturnOrderItem {
  skuId: string;
  returnReason: ReturnReason;
  returnReasonComment?: string | null;
  status: ReturnOrderStatus;
  serialNumber?: string | null;
  conditionStatus?: InventoryConditionStatus | null;
  retrievalId?: string | null;
}

export interface PagingAndSorting {
  pageNumber: number;
  pageSize: number;
  sort?: (Sort | null)[] | null;
}

export interface ProductTierFilter {
  name?: string | null;
  productTierIds?: string[] | null;
}

export interface RetrievalDetails {
  trackingCode?: string | null;
  trackingLink?: string | null;
  shippingService?: string | null;
  labelPdfLink?: string | null;
  labelZplLink?: string | null;
  shipmentId?: string | null;
  shippingCost?: number | null;
  length?: number | null;
  weight?: number | null;
  width?: number | null;
  height?: number | null;
}

export interface ReturnItem {
  inventoryId: string;
  returnReason: ReturnReason;
  returnReasonComment?: string | null;
}

export interface SKUFilter {
  searchString?: string | null;
  categories?: string[] | null;
  brands?: string[] | null;
  deployStatuses?: DeployStatus[] | null;
  deployReasons?: (DeployReason | null)[] | null;
  conditions?: InventoryConditionStatus[] | null;
  shippedDate?: DateRange | null;
  renewalDate?: DateRange | null;
  locations?: (LocationFilter | null)[] | null;
  excludeGenerics?: boolean | null;
  officeIds?: string[] | null;
  countryCode?: string | null;
  tags?: InventoryTagFilter | null;
  metadata?: (SKUMetadataFilter | null)[] | null;
  suppliedBy?: SuppliedBy | null;
  warehouseIds?: string[] | null;
  personIds?: string[] | null;
  priceOptions?: ProductPriceBillingType[] | null;
  billingStartDate?: DateRange | null;
}

export interface SKUMetadataFilter {
  field?: string | null;
  value?: string | null;
}

export interface SKUMetadataInput {
  fieldId: string;
  value: string;
}

export interface Sort {
  direction: SortDirection;
  field: string;
}

export interface StandardPricingInput {
  regionCode: string;
  currencyCode: string;
  purchasePrice: any;
  subscriptionPricing?: SubscriptionPriceInput[] | null;
}

export interface SubscriptionPriceInput {
  length: number;
  interval: SubscriptionInterval;
  price: any;
}

export interface UnknownLegacy {
  skuId?: string | null;
  quantity?: number | null;
  description?: string | null;
  serialNumber?: string | null;
}

export interface UpdateAddress {
  addressLine1?: string | null;
  addressLine2?: string | null;
  administrativeArea?: string | null;
  countryCode?: string | null;
  dependentLocality?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  sortingCode?: string | null;
  phoneNumber?: string | null;
}

export interface UpdateCategoryRefreshMonths {
  category: string;
  refreshMonths: number;
}

export interface UpdateContractProductsParams {
  contractId: string;
  revisionId: number;
  productIdsToAdd: string[];
  productIdsToRemove: string[];
}

export interface UpdateFeatureParams {
  id: string;
  name?: string | null;
  status?: FeatureStatus | null;
  description?: string | null;
  category?: string | null;
}

export interface UpdateInventory {
  serialNumber?: string | null;
  warehouseId?: string | null;
  personSlug?: string | null;
  organizationSlug?: string | null;
  active?: boolean | null;
  conditionStatus?: InventoryConditionStatus | null;
  vendorSku?: string | null;
  vendorCode?: string | null;
  deployStatus?: DeployStatus | null;
  deployReason?: DeployReason | null;
  disassociateOrderItem?: boolean | null;
  mpnValidated?: boolean | null;
  orderItemId?: string | null;
  returnOrderItemId?: string | null;
  clearDeployReason?: boolean | null;
  firstbaseSupplied?: boolean | null;
  renewalDate?: any | null;
  renewalDateOverridden?: boolean | null;
  officeId?: string | null;
  description?: string | null;
  pricingId?: string | null;
}

export interface UpdateOrganizationWarrantyTypeAssignment {
  id: string;
  organizationId: string;
  term: number;
  tier?: string | null;
}

export interface UpdatePerson {
  forename?: string | null;
  surname?: string | null;
  email?: string | null;
  secondaryEmail?: string | null;
  startDate?: any | null;
  offboardingInfo?: WriteOffboardingInfo | null;
  address?: UpdateAddress | null;
  groups?: (GroupName | null)[] | null;
  status?: PersonStatus | null;
  packageSlugs?: (string | null)[] | null;
  integrations?: UpdatePersonIntegrations | null;
}

/**
 *  Feature flag: se-2560-create-api-endpoint-for-generating-workato-api-jwt
 */
export interface UpdatePersonIntegrations {
  workatoUserId?: number | null;
}

export interface UpdateProductTierFeaturesParams {
  productId: string;
  featureIdsToAdd: string[];
  featureIdsToRemove: string[];
}

export interface UpdateProductTierStatusParams {
  productId: string;
  status: ProductTierStatus;
}

export interface UpdateReturnAddress {
  addressLine1?: string | null;
  addressLine2?: string | null;
  administrativeArea?: string | null;
  countryCode?: string | null;
  dependentLocality?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  sortingCode?: string | null;
  phoneNumber?: string | null;
}

export interface UpdateReturnOrder {
  id: string;
  newReturnOrderItems?: (NewReturnOrderItem | null)[] | null;
  shippingAddress?: UpdateReturnAddress | null;
  updateStatus?: boolean | null;
}

export interface UpdateReturnOrderItem {
  id: string;
  status?: OrderStatus | null;
  conditionStatus?: InventoryConditionStatus | null;
  warehouseId?: string | null;
  deliveryDate?: any | null;
  serialNumber?: string | null;
  trackingLink?: string | null;
  inboundTrackingLink?: string | null;
  etaStart?: any | null;
  etaEnd?: any | null;
  shippedDate?: any | null;
  vendorSku?: string | null;
  vendorCode?: string | null;
  forceUpdate?: boolean | null;
  inventoryId?: string | null;
}

export interface UpdateSKU {
  vendorSku?: string | null;
  modelNumber?: string | null;
  modelYear?: string | null;
  partNumber?: string | null;
  upc?: string | null;
  ean?: string | null;
  categoryCode?: string | null;
  availableCountries?: string[] | null;
  productTitle?: string | null;
  productLongDescription?: string | null;
  bullet1?: string | null;
  bullet2?: string | null;
  bullet3?: string | null;
  skuImages?: string[] | null;
  heightUnit?: string | null;
  heightValue?: any | null;
  widthUnit?: string | null;
  widthValue?: any | null;
  lengthUnit?: string | null;
  lengthValue?: any | null;
  colors?: (CreateSKUColor | null)[] | null;
  currency?: string | null;
  costPrice?: any | null;
  numberOfBoxes?: number | null;
  boxes?: (CreateBox | null)[] | null;
  dropShipOnly?: boolean | null;
  includedComponents?: string | null;
  screenSizeUnit?: string | null;
  screenSizeValue?: any | null;
  processorVendor?: string | null;
  processorModel?: string | null;
  processorSpeed?: string | null;
  numberOfCores?: number | null;
  ramSize?: number | null;
  storage?: (CreateStorage | null)[] | null;
  graphicsCardVendor?: string | null;
  graphicsCardModel?: string | null;
  ports?: (CreateConnectionNoGender | null)[] | null;
  includedConnectors?: (CreateConnection | null)[] | null;
  compatibleSKUs?: (string | null)[] | null;
  material?: string | null;
  microphoneIncluded?: boolean | null;
  refreshRate?: string | null;
  resolution?: string | null;
  wireless?: boolean | null;
  keyboardLayout?: string | null;
  leadTimes?: (CreateLeadTime | null)[] | null;
  status?: SKUStatus | null;
  standardPricing?: StandardPricingInput[] | null;
  metadata?: SKUMetadataInput[] | null;
}

/**
 * If vendorSku is provided, vendorCode is required
 * If vendorCode is provided, vendorSku is required
 */
export interface UpdateSKUAssignment {
  vendorSku?: string | null;
  vendorCode?: string | null;
  organizationSlug?: string | null;
  threshold?: number | null;
  active?: boolean | null;
  firstbaseSupplied?: boolean | null;
  minimums?: MinimumInventory[] | null;
}

export interface WriteOffboardingInfo {
  lastDate?: any | null;
  unknownLegacies?: (UnknownLegacy | null)[] | null;
}

export interface WriteOrderItem {
  id?: string | null;
  vendorSku: string;
  vendorCode: string;
  expeditedShippingOption?: ExpeditedShippingOption | null;
  preferredCondition?: PreferredCondition | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
