import { ReactComponent as ChevronDown } from '@firstbase/assets/ChevronDown.svg'
import { ContractResponse } from '@firstbase/types/Contract'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'

type OwnProps = {
  isContractLoading: boolean
  contractResponse?: ContractResponse
}

const CurrenciesAccordion = ({
  isContractLoading,
  contractResponse,
}: OwnProps) => {
  return (
    <Accordion sx={{ mt: 2 }}>
      <AccordionSummary
        expandIcon={<ChevronDown fill="#191A1B" width="1rem" height="1rem" />}
        aria-controls="region & currencies table content"
        id="region & currencies table header"
        disabled={isContractLoading}
      >
        <Typography>
          {isContractLoading ? (
            <Skeleton width="12rem" />
          ) : (
            'Supported regions & currencies'
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table aria-label="region & currencies table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: '6px' }}>
                  <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                    Supported Region
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: '6px' }}>
                  <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                    Charged Currency
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contractResponse?.currencies.map(({ region, currency }) => (
                <TableRow
                  key={region}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ padding: '6px' }}>{region}</TableCell>
                  <TableCell sx={{ padding: '6px' }}>{currency}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}

export default CurrenciesAccordion
