import { useQuery } from '@apollo/client'
import {
  getAllFeatures,
  getAllFeatures_getAllFeatures_features_data,
  getAllFeaturesVariables,
} from '@firstbase/data/ProductTier/__generated__/getAllFeatures'
import { GET_ALL_FEATURES } from '@firstbase/data/ProductTier/productTierFeatureQueries'
import { useMemo } from 'react'

export const useGetProductTierFeatures = (
  variables?: getAllFeaturesVariables,
  id?: getAllFeatures_getAllFeatures_features_data['id']
) => {
  const features = useQuery<getAllFeatures, getAllFeaturesVariables>(
    GET_ALL_FEATURES,
    {
      variables: variables ?? {
        pagingAndSorting: { pageNumber: 1, pageSize: 100 },
      },
    }
  )

  const featureById = useMemo(() => {
    if (!id) {
      return
    }

    return features.data?.getAllFeatures.features?.data.find(
      (feature) => feature.id === id
    )
  }, [features.data?.getAllFeatures.features?.data, id])

  const categories = useMemo(() => {
    if (!features.data?.getAllFeatures.features?.data) {
      return []
    }

    return Array.from(
      new Set(
        features.data.getAllFeatures.features.data
          .map((feature) => feature.category)
          .filter((category): category is string => Boolean(category))
      )
    )
  }, [features.data?.getAllFeatures.features?.data])

  return {
    ...features,
    categories,
    totalElements: features.data?.getAllFeatures.features?.totalElements,
    featureById,
    features: features.data?.getAllFeatures.features?.data,
  }
}
