import Page from '@firstbase/components/atoms/Page'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery as useGQLQuery } from '@apollo/client'
import EditSettings from './EditSettings/EditSettings'
import { AxiosError } from 'axios'

import { Box, Button, Divider, Typography } from '@mui/material'
import PricingSettings from './PricingSettings/PricingSettings'
import { ContractResponse } from '@firstbase/types/Contract'
import ContractSettings from './ContractSettings'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import request from '@firstbase/utils/request'
import CreateSettings from '@views/Home/Client/Settings/CreateSettings/CreateSettings'
import { GET_ORGANIZATION_BY_ID } from '@firstbase/data/Organization/queries'
import OrganizationDetails from '@views/Home/Client/Settings/OrganizationDetails'
import ClientDetailsForm from '@firstbase/views/Home/Client/Settings/ClientDetailsForm/ClientDetailsForm'
import { Role, useAuthState } from '@firstbase/context/auth/AuthProvider'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import {
  getOrganizationById,
  getOrganizationByIdVariables,
} from '@firstbase/data/Organization/__generated__/getOrganizationById'
import { ClientPageParams } from '@firstbase/types/Pages'

const Settings = () => {
  const { clientId }: ClientPageParams = useParams()
  const queryClient = useQueryClient()
  const { hasRole } = useAuthState()
  const [isSettingsFormOpen, setIsSettingsFormOpen] = useState(false)
  const [editClientOpen, setEditClientOpen] = useState<number>(0)
  const { error: contractError, isLoading } = useQuery<any, AxiosError>(
    ['client contract', { clientId }],
    () =>
      request<ContractResponse>({
        url: `/organizations/${clientId}/contract`,
      }),
    // only fetch once - prevent flicker if no contract exists
    { refetchOnWindowFocus: false }
  )
  const {
    loading: isLoadingClient,
    data: { getOrganizationById: client } = {},
  } = useGQLQuery<getOrganizationById, getOrganizationByIdVariables>(
    GET_ORGANIZATION_BY_ID,
    {
      variables: { orgId: clientId },
    }
  )

  const noContractForOrg = useMemo(
    () => contractError?.response?.status === 404,
    [contractError?.response?.status]
  )

  const renderClientTitleAction = () => (
    <ProtectedChildren hasRole={Role.Admin}>
      <Button
        variant="outlined"
        disabled={isLoadingClient}
        color="secondary"
        onClick={() => setEditClientOpen(Math.random() + 1)}
      >
        Edit Client
      </Button>
    </ProtectedChildren>
  )

  const renderClientSettingsAction = () => (
    <ProtectedChildren hasRole={Role.Admin}>
      <Button
        variant="outlined"
        color="secondary"
        disabled={isLoading}
        onClick={() => setIsSettingsFormOpen(true)}
      >
        Edit settings
      </Button>
    </ProtectedChildren>
  )

  const renderOrganizationSettings = () =>
    client && (
      <>
        <Page.TitleSection
          sectionTitle="Organization details"
          subtitleAction={renderClientTitleAction}
        />
        <OrganizationDetails client={client} isLoading={isLoadingClient} />
        <Divider sx={{ my: 2 }} />
      </>
    )

  const renderContractSettings = () => {
    if (noContractForOrg) {
      const renderAddContractSettings = () => (
        <ProtectedChildren hasRole={Role.Admin}>
          <Typography>
            Select supported regions, currencies of invoice, and fee prices by
            adding price settings.
          </Typography>
          <Button
            sx={{ mt: 1.5 }}
            size="large"
            variant="contained"
            onClick={() => setIsSettingsFormOpen(true)}
          >
            Add settings
          </Button>
        </ProtectedChildren>
      )

      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 4,
            gap: 0.5,
          }}
        >
          <Typography variant="h5">
            No price settings exist for this client.
          </Typography>
          {renderAddContractSettings()}
        </Box>
      )
    }

    return (
      <>
        <Page.TitleSection
          sectionTitle="Contract"
          subtitleAction={renderClientSettingsAction}
        />
        <ContractSettings />
        <Divider sx={{ my: 2 }} />
        <PricingSettings />
      </>
    )
  }

  const handleClose = useCallback(
    async (hasCancelled?: boolean) => {
      if (!hasCancelled) {
        await queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey[0].includes('client') &&
            query.queryKey[1].clientId === clientId,
        })
      }
      setIsSettingsFormOpen(false)
    },
    [clientId, queryClient]
  )

  const SettingsAction = noContractForOrg ? CreateSettings : EditSettings

  const isEditClientRendered = hasRole(Role.Admin) && client && !isLoadingClient

  return (
    <>
      {renderOrganizationSettings()}
      {renderContractSettings()}
      {/* only mount when showing to ensure edit contract request
      occurs after a contract change from prior editing */}
      {hasRole(Role.Admin) && isSettingsFormOpen && (
        <SettingsAction open={isSettingsFormOpen} handleClose={handleClose} />
      )}
      {isEditClientRendered && (
        <ClientDetailsForm
          open={!!editClientOpen}
          key={editClientOpen + 1}
          handleClose={() => setEditClientOpen(0)}
          client={client}
          version="EDIT"
        />
      )}
    </>
  )
}

export default Settings
