import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material'
import { FeeType } from '@firstbase/types/Fee'
import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import {
  useSupportedCurrencies,
  useSupportedRegions,
} from '@utils/contractUtils'

type Props = {
  getFeeForCountryCode: (
    countryCode: string,
    feeType: FeeType
  ) => string | number
  updateFeePrice: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    countryCode: string,
    feeType: FeeType,
    defaultValue?: any,
    currencyCode?: string | undefined
  ) => void
  regionIsSupported: (countryCode: string) => boolean
  formCurrenciesByCountry: Record<string, string>
  orgServiceFees: string[]
  overrideCurrencyCode: string | null
}

const ServiceFeesTableForm = ({
  getFeeForCountryCode,
  updateFeePrice,
  regionIsSupported,
  formCurrenciesByCountry,
  orgServiceFees,
  overrideCurrencyCode,
}: Props) => {
  const getDefaultValue = (countryCode: string, feeType: FeeType) => {
    const feeValue = getFeeForCountryCode(countryCode, feeType).toString()

    return feeValue !== NOT_SUPPORTED ? feeValue : undefined
  }

  const supportedRegions = useSupportedRegions()
  const supportedCurrencies = useSupportedCurrencies()

  const contractSupportedCountries = Object.entries(supportedRegions).filter(
    ([countryCode]) => regionIsSupported(countryCode)
  )

  return (
    <>
      {contractSupportedCountries.length > 0 ? (
        <Table aria-label="service fees table" data-testid="service fees table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: 1 }}>
                <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                  Fees
                </Typography>
              </TableCell>
              {contractSupportedCountries.map(([countryCode]) => (
                <TableCell
                  sx={{ padding: 1 }}
                  key={`${countryCode} - service fees`}
                >
                  <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                    {countryCode}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orgServiceFees.map((feeType: FeeType) => (
              <TableRow key={feeType} data-testid={feeType}>
                <TableCell sx={{ padding: 1, minWidth: 180 }}>
                  <Typography variant="inherit">{feeType}</Typography>
                </TableCell>
                {contractSupportedCountries.map(([countryCode]) => (
                  <TableCell sx={{ p: 1 }} key={`${countryCode} - ${feeType}`}>
                    <TextField
                      id={`${feeType} - ${countryCode}`}
                      label="Price"
                      size="small"
                      sx={{ minWidth: 150 }}
                      // when support for region changes, reset field value
                      key={regionIsSupported(countryCode).toString()}
                      defaultValue={getDefaultValue(countryCode, feeType)}
                      placeholder={
                        !getDefaultValue(countryCode, feeType)
                          ? NOT_SUPPORTED
                          : undefined
                      }
                      onBlur={(event) =>
                        updateFeePrice(
                          event,
                          countryCode,
                          feeType,
                          getFeeForCountryCode(countryCode, feeType).toString(),
                          // HACK: If override exists, then use that currency for adding/updating price
                          overrideCurrencyCode ?? undefined
                        )
                      }
                      disabled={!regionIsSupported(countryCode)}
                      inputProps={{
                        'data-testid': `${feeType} - ${countryCode}`,
                      }}
                      InputProps={{
                        startAdornment: (
                          <>
                            {regionIsSupported(countryCode) &&
                              getFeeForCountryCode(
                                countryCode,
                                feeType
                              ).toString() !== NOT_SUPPORTED && (
                                <InputAdornment position="start">
                                  {
                                    supportedCurrencies[
                                      overrideCurrencyCode ||
                                        formCurrenciesByCountry[countryCode]
                                    ]
                                  }
                                </InputAdornment>
                              )}
                          </>
                        ),
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="subtitle1" sx={{ p: 2, textAlign: 'center' }}>
          No regions configured
        </Typography>
      )}
    </>
  )
}

export default ServiceFeesTableForm
