import React, { useState } from 'react'

import Table from '@atoms/Table'
import { PricingRow } from './Product'
import { viewPricingTableColumns } from './pricingTableColumns'
import { PageQueryParams } from '@firstbase/components/atoms/Table/Table'
import { getComparator } from '@firstbase/utils/tableUtils'

type OwnProps = {
  priceData?: PricingRow[]
  isLoading?: boolean
}

function PricingTable({ priceData = [], isLoading = false }: OwnProps) {
  const [prices, setPrices] = useState(priceData)

  const fetch = ({ activeSortId, activeSortDirection }: PageQueryParams) => {
    if (!activeSortId) return null
    setPrices(
      priceData.sort(getComparator(activeSortDirection, activeSortId || ''))
    )
  }

  return (
    <Table
      noDataMessage="No prices available"
      dataKey="countryCode"
      tableId="pricing table"
      query={{ fetch, isLoading, data: { data: prices } }}
      columns={viewPricingTableColumns}
    />
  )
}

export default PricingTable
