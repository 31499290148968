import { FormFeesByCountryByType } from '@firstbase/types/Fee'

import { FeeActions, FeeActionTypes } from './types'
import {
  updateAllOverrideCurrencyCode,
  updateFeeForCountry,
  convertFeesBetweenContractVersions,
} from './feesReducerUtils'

const feesReducer = (
  state: FormFeesByCountryByType,
  action: FeeActions
): FormFeesByCountryByType => {
  switch (action.type) {
    case FeeActionTypes.UPDATE_FEE: {
      const {
        countryCode,
        price,
        feeType,
        overrideCurrencyCode,
        currencyCodeMap,
      } = action.payload
      return updateFeeForCountry(
        state,
        price,
        countryCode,
        feeType,
        currencyCodeMap,
        overrideCurrencyCode
      )
    }
    case FeeActionTypes.OVERRIDE_FEES_CURRENCY: {
      const { currencyCode, billingType } = action.payload
      return updateAllOverrideCurrencyCode(state, currencyCode, billingType)
    }
    case FeeActionTypes.UPDATE_CONTRACT_VERSION: {
      const { version } = action.payload
      return convertFeesBetweenContractVersions(state, version)
    }
    default:
      return state
  }
}

export default feesReducer
