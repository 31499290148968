import FLAGS from '@firstbase/constants/featureFlags'
import { useFlags } from 'launchdarkly-react-client-sdk'

type FlagKey = keyof typeof FLAGS
type FeatureName = typeof FLAGS[FlagKey]

export function useFeatureFlag(flagName: FeatureName): boolean {
  const flags = useFlags()

  return !!flags[flagName]
}
