import React, { useEffect, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'

import FLAGS from '@firstbase/constants/featureFlags'
import { useFeatureFlag } from '@firstbase/hooks/useFeatureFlag'
import { SKU } from '@firstbase/types/Product'
import { SKUMetadataInput, SKUType, StandardPricingInput } from '@globalTypes'

import { getAllRegions } from '@firstbase/data/Geo/__generated__/getAllRegions'
import { GET_ALL_REGIONS } from '@firstbase/data/Geo/queries'
import {
  getActiveAssignmentsForSku,
  getActiveAssignmentsForSkuVariables,
} from '@firstbase/data/SKU/__generated__/getActiveAssignmentsForSku'
import {
  getAllSKUMetadataOptionValues,
  getAllSKUMetadataOptionValuesVariables,
} from '@firstbase/data/SKU/__generated__/getAllSKUMetadataOptionValues'
import {
  getNewProductData,
  getNewProductDataVariables,
} from '@firstbase/data/SKU/__generated__/getNewProductData'
import {
  GET_ACTIVE_ASSIGNMENTS_FOR_SKU,
  GET_ALL_SKUS,
  GET_ALL_SKU_METADATA_OPTIONS_VALUES,
  GET_NEW_PRODUCT_DATA,
} from '@firstbase/data/SKU/queries'

import { filterNullsOut } from '@firstbase/utils/array/filterNullsOut'
import {
  formValuesToStandardPricing,
  pricingHasChanged,
  standardPricingToFormValues,
  validateStandardPricing,
} from '@firstbase/views/Home/Platform/Products/StandardPricingTable/utils'
import { useMetadataItems } from '../hooks/useMetadataItems'
import {
  APPLE_US,
  formatExistingMetadata,
  getAssignedOrgNames,
  getSkuCardChips,
  getSkuCardTitle,
  getValidMetadataFields,
  getVendorName,
  transformSkuMetadata,
} from '../utils'

import Page from '@atoms/Page'
import SectionError from '@firstbase/components/atoms/SectionError'
import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import { BottomBarProps } from '@firstbase/components/molecules/FullscreenModal/types'
import {
  getAllSKUs,
  getAllSKUsVariables,
  getAllSKUs_getAllSKUs_data,
} from '@firstbase/data/SKU/__generated__/getAllSKUs'
import CustomerCatalogWarningModal from '@firstbase/views/Home/Platform/Products/CustomerCatalogWarningModal'
import ProductLoadingSkeleton from '@firstbase/views/Home/Platform/Products/ProductLoadingSkeleton'
import StandardPricingTable from '@firstbase/views/Home/Platform/Products/StandardPricingTable'
import ArchiveButton from '../ArchiveButton'
import { CategorySelect } from '@firstbase/components/molecules/CategorySelect'
import { VendorSelect } from '@firstbase/components/molecules/VendorSelect'
import { SkuProductCard } from '@firstbase/components/molecules/SkuProductCard'
import { FilteredSkuCardList } from '@firstbase/components/molecules/FilteredSkuCardList'
import ImageUploader from '../ImageUploader'
import MetadataOptions from '../MetadataOptions'
import ProductTitle from '../ProductTitle'
import { useMetadataValid } from './useMetadataValid'

export type Product = {
  title: string
  vendor: string
  category: string
  image: string
  genericCategory: boolean
  vendorSku?: string
  standardPricing?: StandardPricingInput[]
  metadata?: SKUMetadataInput[] | null
}

type OwnProps = {
  handleClose: () => void
  open: boolean
  error?: string
  saving?: boolean
  handleSubmit: (product: Product, standardCatalog?: boolean) => void
  product?: SKU
  staticGenericCategory?: boolean
}

const PAGE_SIZE = 25

const GlobalProductForm = ({
  handleClose,
  handleSubmit,
  open,
  product,
  error,
  saving,
  staticGenericCategory,
}: OwnProps) => {
  const isCreate = !product
  const standardCatalogM1Flag = useFeatureFlag(
    FLAGS.SE_4591_STANDARD_CATALOG_M1
  )
  const skuMetadataM1Flag = useFeatureFlag(FLAGS.PE_517_SKU_METADATA)

  const [genericCategory, setGenericCategory] = useState<boolean>(
    staticGenericCategory ?? !!product?.genericCategory
  )
  const [title, setTitle] = useState(product?.skuInformation.productTitle || '')
  const isDefaultValue = skuMetadataM1Flag && !genericCategory
  const [category, setCategory] = useState(
    product?.category.code || (isDefaultValue ? SKUType.COMPUTER : '')
  )
  const [vendor, setVendor] = useState(
    product?.vendor.code || (isDefaultValue ? APPLE_US : '')
  )
  const [mpn, setMpn] = useState(product?.vendorSku || '')

  const [image, setImage] = useState(product?.skuImages?.[0] || '')
  const [isValidImage, setIsValidImage] = useState<boolean>(!!product)
  const [showImageError, setShowImageError] = useState<boolean>(false)

  const [hasMetadataError, setHasMetadataError] = useState(false)

  const [isStandardCatalog, setIsStandardCatalog] = useState(
    product?.standardCatalog || false
  )
  const [standardPricing, setStandardPricing] = useState(
    standardPricingToFormValues(product?.standardPricing || [])
  )

  const [showCustomerCatalogWarningModal, setShowCustomerCatalogWarningModal] =
    useState(false)
  const [hasShownErrorMessage, setHasShownErrorMessage] = useState(false)

  const [pageNumber, setPageNumber] = useState(1)
  const [filteredSkus, setFilteredSkus] = useState<
    getAllSKUs_getAllSKUs_data[] | []
  >([])
  const [hasMore, setHasMore] = useState(true)
  const [isRefetchedDataLoading, setIsRefetchedDataLoading] = useState(true)

  const { enqueueSnackbar } = useSnackbar()

  const modalTitle = isCreate ? 'Add new product' : 'Edit product'
  const pageTitle = isCreate
    ? 'Create new product'
    : `Edit ${product.skuInformation.productTitle}`

  const {
    data,
    loading,
    error: skuOptionsError,
  } = useQuery<getNewProductData, getNewProductDataVariables>(
    GET_NEW_PRODUCT_DATA,
    {
      variables: { pagingAndSorting: { pageSize: 1000, pageNumber: 1 } },
    }
  )

  const { data: regions, loading: regionsLoading } = useQuery<getAllRegions>(
    GET_ALL_REGIONS,
    {
      skip: !standardCatalogM1Flag,
    }
  )

  const { data: skuAssignments, loading: skuAssignmentsLoading } = useQuery<
    getActiveAssignmentsForSku,
    getActiveAssignmentsForSkuVariables
  >(GET_ACTIVE_ASSIGNMENTS_FOR_SKU, {
    variables: {
      skuId: product?.id || '',
      pagingAndSorting: {
        pageNumber: 1,
        pageSize: 3,
      },
    },
    skip: !product,
  })

  const {
    data: skuMetadataOptions,
    loading: skuMetadataOptionsLoading,
    error: skuMetadataOptionsError,
  } = useQuery<
    getAllSKUMetadataOptionValues,
    getAllSKUMetadataOptionValuesVariables
  >(GET_ALL_SKU_METADATA_OPTIONS_VALUES, {
    variables: {
      categoryCode: category,
      vendorCode: vendor,
    },
    skip: !vendor || !category || genericCategory || !skuMetadataM1Flag,
    fetchPolicy: 'cache-and-network',
  })

  const skuMetadataData = useMemo(() => {
    return filterNullsOut(
      skuMetadataOptions?.getAllSKUMetadataOptionValues || []
    )
  }, [skuMetadataOptions])

  const formattedMetadata = useMemo(() => {
    if (isCreate) return []
    const cleanedMetadata = filterNullsOut(product?.metadata || [])
    return formatExistingMetadata(cleanedMetadata, skuMetadataData)
  }, [isCreate, product?.metadata, skuMetadataData])

  const {
    metadata,
    handleMetadataChange,
    setToInitialMetadata,
    getSelectedMetadata,
  } = useMetadataItems(formattedMetadata)

  const metadataFilter = useMemo(() => {
    return metadata.map((metaItem) => ({
      field: metaItem.fieldName,
      value: metaItem.value,
    }))
  }, [metadata])

  const updateSkus = (
    newSkus: getAllSKUs_getAllSKUs_data[],
    currentPage: number
  ) => {
    setFilteredSkus((prevSkus) =>
      currentPage === 1 ? newSkus : [...prevSkus, ...newSkus]
    )
    if (newSkus.length === 0 || newSkus.length < PAGE_SIZE) {
      setHasMore(false)
    }
  }

  const {
    data: filteredSkusQueryData,
    loading: filteredSKUsLoading,
    error: filteredSKUsError,
    fetchMore: filteredSKUsFetchMore,
    refetch: refetchFilteredSKUs,
  } = useQuery<getAllSKUs, getAllSKUsVariables>(GET_ALL_SKUS, {
    variables: {
      filter: {
        metadata: metadataFilter,
        categories: [category],
        brands: [vendor],
      },
      pageNumber,
      pageSize: PAGE_SIZE,
    },
    notifyOnNetworkStatusChange: true,
    skip:
      !skuMetadataM1Flag || category !== SKUType.COMPUTER || !!genericCategory,
  })

  // When editing, exclude the current SKU from the filtered list to avoid duplicate display
  const filteredSkusWithoutCurrent = useMemo(
    () =>
      isCreate
        ? filteredSkus
        : filteredSkus.filter((sku) => sku.id !== product?.id),
    [isCreate, filteredSkus, product?.id]
  )

  // Calculate the adjusted total elements:
  // - For create view: use the total from filteredSkusQueryData
  // - For edit view: subtract 1 from the total if the current product is in the filtered list
  const adjustedTotalElements = useMemo(() => {
    const totalElements = filteredSkusQueryData?.getAllSKUs?.totalElements ?? 0

    if (isCreate) {
      return totalElements
    }

    const isCurrentSkuInList = filteredSkus.some(
      (sku) => sku.id === product?.id
    )

    return Math.max(totalElements - (isCurrentSkuInList ? 1 : 0), 0)
  }, [
    isCreate,
    filteredSkusQueryData?.getAllSKUs?.totalElements,
    filteredSkus,
    product?.id,
  ])

  const isMetadataValid = useMetadataValid({
    metadata,
    filteredSkus: filteredSkusWithoutCurrent,
    isCreate,
    genericCategory,
    skuMetadataM1Flag,
    hasMetadataError,
    filteredSKUsTotal: adjustedTotalElements,
  })

  const handleLoadMore = () => {
    const nextPageNumber = pageNumber + 1

    filteredSKUsFetchMore({
      variables: {
        pageNumber: nextPageNumber,
        pageSize: PAGE_SIZE,
      },
    }).then((fetchMoreResult) => {
      const fetchedSKUs = fetchMoreResult.data.getAllSKUs.data
      setPageNumber(nextPageNumber)
      updateSkus(fetchedSKUs, nextPageNumber)
    })
  }
  // Reset pagination and refetch data when filters change
  useEffect(() => {
    if (!skuMetadataM1Flag) return
    const resetAndRefetch = async () => {
      setIsRefetchedDataLoading(true)
      setPageNumber(1)
      setFilteredSkus([])
      setHasMore(true)
      const newFilter = {
        filter: {
          metadata: metadataFilter,
          categories: [category],
          brands: [vendor],
        },
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      }
      const result = await refetchFilteredSKUs(newFilter)

      if (result.data) {
        setIsRefetchedDataLoading(false)
        updateSkus(result.data.getAllSKUs.data, 1)
      }
    }
    resetAndRefetch()
  }, [metadataFilter, category, vendor, refetchFilteredSKUs, skuMetadataM1Flag])

  const standardPricingValid = useMemo(() => {
    if (!standardCatalogM1Flag || genericCategory) return true

    return validateStandardPricing(
      formValuesToStandardPricing(standardPricing),
      isStandardCatalog
    )
  }, [
    standardPricing,
    isStandardCatalog,
    standardCatalogM1Flag,
    genericCategory,
  ])

  const standardCatalogStatusModified = () =>
    isStandardCatalog !== !!product?.standardCatalog

  const standardPricingAllowed = () => standardCatalogM1Flag && !genericCategory

  const shouldShowPricingChangedConfirmation = (): boolean => {
    const hasAssignedCustomers =
      !!skuAssignments?.getActiveAssignmentsForSku.totalElements

    return (
      hasAssignedCustomers &&
      pricingHasChanged(product?.standardPricing || [], standardPricing)
    )
  }

  const submitForm = () => {
    setHasShownErrorMessage(false)
    setShowImageError(false)

    const productSubmitAttrs: Product = {
      title: title.trim(),
      vendor,
      category,
      image,
      genericCategory,
      standardPricing: standardPricingAllowed()
        ? formValuesToStandardPricing(standardPricing)
        : undefined,
    }
    if (metadata.length > 0 && skuMetadataM1Flag) {
      productSubmitAttrs.metadata = metadata.map(({ fieldId, value }) => ({
        fieldId,
        value,
      }))
    }
    if (mpn.trim().length > 0) {
      productSubmitAttrs.vendorSku = mpn.trim()
    }
    const args: Parameters<typeof handleSubmit> = [productSubmitAttrs]

    if (standardPricingAllowed() && standardCatalogStatusModified()) {
      args.push(isStandardCatalog)
    }

    handleSubmit(...args)
  }

  const onSubmit = (skipChecks?: { skipStandardPricingCheck: boolean }) => {
    if (!standardPricingValid) {
      enqueueSnackbar(
        'Purchase price is required for one region. All regions with subscription price must have purchase price.',
        { variant: 'warning' }
      )
      return
    }

    if (
      !skipChecks?.skipStandardPricingCheck &&
      shouldShowPricingChangedConfirmation()
    ) {
      setShowCustomerCatalogWarningModal(true)
      return
    }

    submitForm()
  }

  useEffect(() => {
    if (isCreate && genericCategory && category) {
      setTitle(
        data!.getAllCategories!.data.find(({ code }) => code === category)
          ?.nameSingular!
      )
    }
  }, [category, data, genericCategory, isCreate, setTitle])

  useEffect(() => {
    if (!skuMetadataData) return
    const requiredFields = getValidMetadataFields(skuMetadataData, true)

    let hasError = false

    requiredFields.forEach((field) => {
      const metaValue = metadata.find(
        (meta) => meta.fieldId === field.id
      )?.value

      if (!metaValue) {
        hasError = true
      }
    })

    setHasMetadataError(hasError)
  }, [metadata, skuMetadataData])

  // Handle errors
  useEffect(() => {
    if (!error) return

    if (
      /.*(createSKU|updateSKU)\.(createSKU|updateSKU)\.skuImages\[0\]\.<list element>: must match/.test(
        error
      )
    ) {
      setShowImageError(true)
    } else if (!hasShownErrorMessage) {
      setHasShownErrorMessage(true)
      const action = isCreate ? 'create' : 'update'
      enqueueSnackbar(
        `Unable to ${action} ${title}. Please check your information and try again.`,
        { variant: 'error' }
      )
    }
  }, [error, enqueueSnackbar, hasShownErrorMessage, title, isCreate])

  const isTitleValid =
    !skuMetadataM1Flag || genericCategory || category !== SKUType.COMPUTER
      ? title.trim() !== ''
      : true

  const isVendorOrCategoryValid = (!!vendor || genericCategory) && !!category

  const isFormComplete =
    isMetadataValid && isValidImage && isVendorOrCategoryValid && isTitleValid

  const handleToggleGenericCategory = () => {
    setGenericCategory((prev) => !prev)
    setVendor('')
  }

  const isLoading =
    loading ||
    regionsLoading ||
    skuAssignmentsLoading ||
    skuMetadataOptionsLoading

  if (open && isLoading) {
    return <ProductLoadingSkeleton />
  }

  const continueProps: BottomBarProps['continueProps'] = {
    label: 'Save',
    disabled: saving || !isFormComplete,
    onClick: () => onSubmit(),
  }

  const shouldShowGenericCategoryToggle = () => {
    return staticGenericCategory === undefined && (isCreate || genericCategory)
  }

  const confirmUpdatePricing = () => {
    setShowCustomerCatalogWarningModal(false)
    onSubmit({ skipStandardPricingCheck: true })
  }

  const renderStandardPricingTable = () => {
    if (!standardCatalogM1Flag || genericCategory === true) return null

    return (
      <StandardPricingTable
        standardPricing={standardPricing}
        onChange={setStandardPricing}
        regions={regions?.getAllRegions || []}
      />
    )
  }

  const renderStandardCatalogCheckbox = () => {
    if (!standardCatalogM1Flag || genericCategory) return null

    return (
      <FormControlLabel
        sx={{ marginBottom: 3 }}
        control={
          <Checkbox
            checked={isStandardCatalog}
            data-testid="standard-catalog-checkbox"
            onChange={(e) => setIsStandardCatalog(e.target.checked)}
          />
        }
        label={
          <>
            <Typography>Firstbase Standard Catalog</Typography>
            <Typography variant="body2">
              This determines if the admin can add the SKU to their
              organization's catalog using the Firstbase app.
            </Typography>
          </>
        }
      />
    )
  }

  const renderMetadataFields = (isRequired: boolean) => {
    const metadataFields = getValidMetadataFields(skuMetadataData, isRequired)

    if (metadataFields.length > 0 && skuMetadataM1Flag)
      return (
        <MetadataOptions
          isCreate={isCreate}
          categoryCode={category}
          metadataFields={metadataFields}
          getSelectedMetadata={getSelectedMetadata}
          handleMetadataChange={handleMetadataChange}
          vendorCode={vendor}
          formattedMetadata={formattedMetadata}
        />
      )
  }

  const renderProductTitle = () => {
    if (skuMetadataData.length > 0 && skuMetadataM1Flag) {
      return null
    }
    return (
      <ProductTitle
        genericCategory={genericCategory}
        category={category}
        title={title}
        setTitle={setTitle}
      />
    )
  }

  const renderSpecsHeader = (isOptional?: boolean) => {
    if (!skuMetadataM1Flag || genericCategory) return null
    const text = isOptional ? 'Optional Specs' : 'Required Specs'
    return <Typography variant="body1">{text}</Typography>
  }

  const displaySidePane =
    skuMetadataM1Flag && !genericCategory && category === SKUType.COMPUTER

  const renderComputerSkuCard = () => {
    const headerTitle = isCreate ? 'Adding product as:' : 'Editing product as:'
    return (
      <>
        <Typography sx={{ marginBottom: 1, paddingTop: 2 }} variant="body1">
          {headerTitle}
        </Typography>
        <SkuProductCard
          image={image}
          vendor={getVendorName(vendor, data)}
          skuTitle={getSkuCardTitle(metadata, skuMetadataData)}
          skuChips={getSkuCardChips(metadata, skuMetadataData, vendor)}
        />
      </>
    )
  }

  const renderFilteredComputerSkuCard = () => {
    return (
      <>
        <Typography variant="body1">
          Existing computers in platform catalog ({adjustedTotalElements})
        </Typography>
        <FilteredSkuCardList
          filteredSKUsError={filteredSKUsError}
          filteredSKUsLoading={filteredSKUsLoading || isRefetchedDataLoading}
          handleLoadMore={handleLoadMore}
          filteredSkus={filteredSkusWithoutCurrent}
          hasMore={hasMore}
          getSkuTitle={(sku) =>
            getSkuCardTitle(
              transformSkuMetadata(filterNullsOut(sku.metadata || [])),
              skuMetadataData
            )
          }
          getSkuChips={(sku) =>
            getSkuCardChips(
              transformSkuMetadata(filterNullsOut(sku.metadata || [])),
              skuMetadataData,
              vendor
            )
          }
        />
      </>
    )
  }

  return (
    <FullscreenModal
      open={open}
      handleClose={handleClose}
      handleBack={handleClose}
      closeDisabled={false}
      title={modalTitle}
      continueProps={continueProps}
    >
      <Page.TitleSection sectionTitle={pageTitle} />
      {(skuOptionsError || skuMetadataOptionsError) && (
        <SectionError
          title="Something went wrong"
          error={(skuOptionsError || skuMetadataOptionsError) as Error}
        />
      )}

      {shouldShowGenericCategoryToggle() && (
        <FormControlLabel
          sx={{ mb: 2 }}
          control={
            <Checkbox
              checked={!!genericCategory}
              disabled={!isCreate}
              data-testid={`generic-category?state=${!!genericCategory}`}
              onChange={handleToggleGenericCategory}
            />
          }
          label={<Typography>Generic Category SKU</Typography>}
        />
      )}
      {renderStandardCatalogCheckbox()}
      <Grid container spacing={2} sx={{ gap: 2 }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginBottom: '24px',
          }}
        >
          {renderSpecsHeader()}
          <CategorySelect
            data={data}
            product={product}
            category={category}
            setCategory={setCategory}
            setToInitialMetadata={setToInitialMetadata}
          />
          <VendorSelect
            data={data}
            vendor={vendor}
            setToInitialMetadata={setToInitialMetadata}
            genericCategory={genericCategory}
            disabled={!isCreate}
            setVendor={(code) => setVendor(code)}
          />
          {renderMetadataFields(true)}
          {renderProductTitle()}
          <ImageUploader
            setIsValidImage={setIsValidImage}
            isValidImage={isValidImage}
            setImage={setImage}
            showImageError={showImageError}
            image={image}
          />
          {renderSpecsHeader(true)}
          {renderMetadataFields(false)}
          {!genericCategory ? (
            <TextField
              fullWidth
              id="mpn"
              label="Manufacturer Part Number (MPN)"
              size="small"
              inputProps={{
                value: mpn,
                onChange: (e) => setMpn((e.target as HTMLInputElement).value),
              }}
            />
          ) : null}
        </Grid>
        {displaySidePane ? (
          <div>
            {renderComputerSkuCard()}
            {renderFilteredComputerSkuCard()}
          </div>
        ) : null}
      </Grid>
      {renderStandardPricingTable()}
      <ArchiveButton
        product={product}
        isCreate={isCreate}
        handleClose={handleClose}
      />
      <CustomerCatalogWarningModal
        open={showCustomerCatalogWarningModal}
        organizationNames={getAssignedOrgNames(skuAssignments)}
        totalOrganizations={
          skuAssignments?.getActiveAssignmentsForSku.totalElements || 0
        }
        onContinue={() => confirmUpdatePricing()}
        onCancel={() => setShowCustomerCatalogWarningModal(false)}
      />
    </FullscreenModal>
  )
}

export default GlobalProductForm
