import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Skeleton, Stack } from '@mui/material'

import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import Page from '@firstbase/components/atoms/Page'

import { WarrantyMetadataConfigCategorySelect } from './WarrantyMetadataConfigCategorySelect'
import { WarrantyMetadataConfigSwitch } from './WarrantyMetadataConfigSwitch'
import { useMetadataConfigFields } from './useMetadataConfigFields'

export const WarrantyMetadataConfig = () => {
  const [category, setCategory] = useState('')

  const history = useHistory()
  const closeModal = () => history.replace('/platform/warranties')

  const { fieldNames, loading, getFieldValue } =
    useMetadataConfigFields(category)

  return (
    <FullscreenModal
      title="Configure Warranty Metadata"
      open={true}
      handleClose={closeModal}
    >
      <Page.TitleSection sectionTitle="Configure Metadata Fields for Warranty Categories" />
      <Stack spacing={2}>
        <WarrantyMetadataConfigCategorySelect
          category={category}
          setCategory={setCategory}
        />
        {loading && <Skeleton variant="rectangular" width={300} height={118} />}
        {!loading && !!category && (
          <>
            {fieldNames.map((fieldName) => {
              const initialValue = getFieldValue(fieldName)
              return (
                <WarrantyMetadataConfigSwitch
                  key={`${category}-${fieldName}`}
                  categoryCode={category}
                  fieldName={fieldName}
                  initialValue={!!initialValue}
                  id={initialValue?.id}
                />
              )
            })}
          </>
        )}
      </Stack>
    </FullscreenModal>
  )
}
