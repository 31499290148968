import React, { useState } from 'react'
import {
  Autocomplete,
  Grid,
  TextField,
  createFilterOptions,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues } from '@firstbase/data/SKU/__generated__/getAllSKUMetadataOptionValues'
import { filterNullsOut } from '@firstbase/utils/array/filterNullsOut'
import { useAuthState, Role } from '@firstbase/context/auth/AuthProvider'
import {
  CREATE_SKU_METADATA_OPTION_VALUE,
  DELETE_SKU_METADATA_OPTION_VALUE,
} from '@firstbase/data/SKU/mutations'
import { GET_ALL_SKU_METADATA_OPTIONS_VALUES } from '@firstbase/data/SKU/queries'
import {
  createSKUMetadataOptionValue as createSKUMetadataOptionValueType,
  createSKUMetadataOptionValueVariables,
} from '@firstbase/data/SKU/__generated__/createSKUMetadataOptionValue'
import {
  deleteSKUMetadataOptionValue as deleteSKUMetadataOptionValueType,
  deleteSKUMetadataOptionValueVariables,
} from '@firstbase/data/SKU/__generated__/deleteSKUMetadataOptionValue'
import { formatSkuMetadataFieldLabel } from '@utils/formatSkuMetadataFieldLabel'

import { HandleMetadataChange } from './hooks/useMetadataItems'
import {
  getMetadataFieldOptions,
  getNewlySelectedValue,
  getOptionLabel,
  removeOptionInCache,
} from './utils'
import { MetadataItem, MetadataOption } from './types'
import MetadataDropdownOption from './MetadataDropdownOption'

interface OwnProps {
  isCreate: boolean
  categoryCode: string
  handleMetadataChange: HandleMetadataChange
  metadataFields: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues[]
  getSelectedMetadata: (fieldId: string) => MetadataOption | null
  vendorCode: string
  formattedMetadata: MetadataItem[]
}

const filter = createFilterOptions({
  stringify: (option: MetadataOption) => option.label.toLowerCase(),
  matchFrom: 'any',
  trim: true,
})

const MetadataOptions = ({
  isCreate,
  categoryCode,
  metadataFields,
  handleMetadataChange,
  getSelectedMetadata,
  vendorCode,
  formattedMetadata,
}: OwnProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const { hasRole } = useAuthState()
  const isCatalogAdmin = hasRole(Role.CatalogAdmin)

  const [confirmDeleteMode, setConfirmDeleteMode] = useState<
    Record<string, boolean>
  >({})

  const [createSkuMetadataOptionValue] = useMutation<
    createSKUMetadataOptionValueType,
    createSKUMetadataOptionValueVariables
  >(CREATE_SKU_METADATA_OPTION_VALUE, {
    refetchQueries: [
      {
        query: GET_ALL_SKU_METADATA_OPTIONS_VALUES,
        variables: {
          categoryCode,
          vendorCode,
        },
      },
    ],
  })

  const [deleteSkuMetadataOptionValueType] = useMutation<
    deleteSKUMetadataOptionValueType,
    deleteSKUMetadataOptionValueVariables
  >(DELETE_SKU_METADATA_OPTION_VALUE)

  const handleDeleteSkuMetadataOption = (
    option: MetadataOption,
    fieldId: string
  ) => {
    const { id, label } = option

    deleteSkuMetadataOptionValueType({
      variables: {
        id,
      },
      onCompleted: () => {
        enqueueSnackbar(`Option "${label}" was successfully deleted`)
      },
      onError: () => {
        enqueueSnackbar(
          `An error was encountered when trying to delete "${label}"`
        )
      },
      optimisticResponse: {
        deleteSKUMetadataOptionValue: id,
      },
      update: (cache) => {
        removeOptionInCache(cache, id, fieldId)
      },
    })
  }

  const toggleConfirmDeleteMode = (optionId: string, val: boolean) =>
    setConfirmDeleteMode((prevState) => ({
      ...prevState,
      [optionId]: val,
    }))

  return (
    <Grid container spacing={2}>
      {metadataFields.map((field) => {
        const selectedMetadata = getSelectedMetadata(field.id)
        const options = getMetadataFieldOptions(
          filterNullsOut(field.values || []),
          formattedMetadata,
          field.id
        )
        // for M1, we want to allow users to edit the SKU without having to
        // provide all required values, and make sure that users can't clear
        // fields with previously assigned values
        const hasPrevAssignedValueAndIsRequired =
          Boolean(
            formattedMetadata.find((option) => option.fieldId === field.id)
          ) && field.required

        return (
          <Grid key={field.id} item xs={12} md={6}>
            <Autocomplete
              disablePortal
              id={field.id}
              value={selectedMetadata}
              options={options}
              disableClearable={
                isCreate ? field.required : hasPrevAssignedValueAndIsRequired
              }
              onChange={(event, newValue) => {
                const fieldId = field.id
                const parsedNewValue = getNewlySelectedValue(newValue, options)

                handleMetadataChange(field, parsedNewValue)

                if (typeof parsedNewValue === 'string') {
                  createSkuMetadataOptionValue({
                    variables: {
                      categoryCode,
                      vendorCode,
                      fieldId,
                      value: parsedNewValue.trim(),
                    },
                    /**
                     * Update the value in state, since a temporary id
                     * was used while this was being resolved. Additionally,
                     * the list also needs to be updated, to contain the new
                     * option, but that is done by having the cache updated
                     * with the response from this mutation.
                     */
                    onCompleted: (data) => {
                      const newlyCreatedOption =
                        data.createSKUMetadataOptionValue?.values?.find(
                          (value) => value?.value === parsedNewValue
                        )

                      if (newlyCreatedOption) {
                        handleMetadataChange(field, {
                          id: newlyCreatedOption.id,
                          label: newlyCreatedOption.value,
                        })
                      }
                    },
                    onError: () => {
                      handleMetadataChange(field, null)
                      enqueueSnackbar(
                        `There was an error when trying to create "${parsedNewValue}" for ${field.name}`
                      )
                    },
                  })
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={formatSkuMetadataFieldLabel(field.name)}
                  // for M1, we want to allow users to edit the SKU without having to
                  // provide all required values
                  required={isCreate ? field.required : false}
                />
              )}
              // used for setting the display value of the input
              getOptionLabel={getOptionLabel}
              /**
               * used to overwrite prop above, so that the add new option
               * appears correctly in the dropdown option component
               */
              renderOption={(props, option) => (
                <MetadataDropdownOption
                  handleDeleteOption={() =>
                    handleDeleteSkuMetadataOption(option, field.id)
                  }
                  isInConfirmDeleteMode={confirmDeleteMode[option.id] || false}
                  materialUiProps={props}
                  option={option}
                  toggleConfirmDeleteMode={(val) =>
                    toggleConfirmDeleteMode(option.id, val)
                  }
                />
              )}
              filterOptions={(unfilteredOptions, params) => {
                const filtered = filter(unfilteredOptions, params)
                const { inputValue } = params
                const isExactMatch = unfilteredOptions.some(
                  (option) =>
                    option.label.toLowerCase() ===
                    inputValue.toLowerCase().trim()
                )

                if (isCatalogAdmin && inputValue !== '' && !isExactMatch) {
                  filtered.push({
                    inputValue,
                    label: `Add "${inputValue}"`,
                    id: 'temp-id',
                  })
                }

                return filtered
              }}
              freeSolo={isCatalogAdmin}
              handleHomeEndKeys
              clearOnBlur
              selectOnFocus
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default MetadataOptions
