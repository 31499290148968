const FLAGS = {
  SE_3028_CHRISTMAS: 'se-3028-mgmt-christmas',
  SE_3043_DELETE_USER: 'se-3043-mgmt-add-delete-user',
  SE_3065_REMOVE_GLOBAL_PRODUCT: 'se-3065-mgmt-remove-global-product',
  SE_2757_RETURN_ORDER_LEGACY_ITEMS_ADD:
    'se-2757-mgmt-add-legacy-items-to-return-order',
  SE_3494_EDIT_RETURN_ORDER_SERIAL_AND_DEPLOY_REASON:
    'se-3494-edit-return-order-serial',
  SE_3266_UPDATE_RETURN_ORDER_ADDRESS: 'se-3266-update-return-order-address',
  SE_3407_CREATE_RETRIEVAL_METHOD: 'se-3407-create-retrieval-method',
  SE_3854_SHOW_CONFIRMED_RETRIEVALS: 'se-3854-show-confirmed-retrievals',
  SE_2632_SETTING_GIFTED_FOR_CATEGORY: 'se-2632-setting-gifted-for-category',
  SE_4230_REFRESH_RATES: 'se-4230-refresh-rates',
  SE_3532_OVERRIDE_DIFFERENT_PRICES: 'se-3532-override-different-prices',
  SE_4972_ADD_SEARCH_BY_NAME_FILTER: 'se-4972-add-search-by-name-filter',
  SE_4562_USAGE_REPORT_AND_FEES_MVP: 'se-4562-usage-report-and-fees-mvp',
  SE_4591_STANDARD_CATALOG_M1: 'se-4591-standard-catalog-m1',
  PE_139_DISABLE_SUBSCRIPTION_OPTION: 'pe-139-disable-subscription-option',
  PE_517_SKU_METADATA: 'PE-517-SKU-metadata',
  PE_1317_MGMT_ASSISTANT: 'pe-1317-mgmt-assistant',
  // Remove flag after being on for 6 weeks in prod, with no specific targeting
  WARRANTIES_MGMT: 'warranties-mgmt',
} as const

export default FLAGS
