import React, { useEffect, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material'

import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import FLAGS from '@firstbase/constants/featureFlags'

import EditOverrideManagementFees from './OverrideManagementFeesForm'
import {
  isOverridden,
  isOverriddenWithDifferentPrices,
} from '../../settingsUtils'
import {
  FeeType,
  FeeBillingType,
  FeesRow,
  FormFeesByCountryByType,
} from '@firstbase/types/Fee'
import {
  useSupportedCurrencies,
  useSupportedRegions,
} from '@utils/contractUtils'

type OwnProps = {
  formCurrenciesByCountry: Record<string, string>
  getFeeForCountryCode: (
    countryCode: string,
    feeType: FeeType
  ) => number | string
  updateFeePrice: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    countryCode: string,
    feeType: FeeType,
    defaultValue?: any,
    currencyCode?: string
  ) => void
  regionIsSupported: (countryCode: string) => boolean
  formFeesByCountryByType: FormFeesByCountryByType
  defaultsToRegion: string
  updateAllOverrideCurrencyCode: (
    currencyCode: string | undefined,
    billingType?: FeeBillingType
  ) => void
  renderPrepaidSeats: () => React.ReactNode
}

function ManagementFeesTableForm({
  formCurrenciesByCountry,
  getFeeForCountryCode,
  updateFeePrice,
  regionIsSupported,
  formFeesByCountryByType,
  defaultsToRegion,
  updateAllOverrideCurrencyCode,
  renderPrepaidSeats,
}: OwnProps) {
  const flags = useFlags()
  const overrideDifferentPrices = flags[FLAGS.SE_3532_OVERRIDE_DIFFERENT_PRICES]
  const supportedCurrencies = useSupportedCurrencies()

  const defaultOverrideCurrencyCode = useMemo(
    () => formCurrenciesByCountry[defaultsToRegion],
    [formCurrenciesByCountry, defaultsToRegion]
  )
  const subscriptionFees = useMemo(
    () =>
      Object.values(formFeesByCountryByType).map((regionFees) =>
        Object.values(regionFees).filter(
          ({ billingType }) => billingType === 'Subscription'
        )
      ),
    [formFeesByCountryByType]
  )

  const supportedRegions = useSupportedRegions()

  const regionsToOverride = useMemo(
    () => Object.keys(supportedRegions).filter(regionIsSupported),
    [supportedRegions, regionIsSupported]
  )

  const [override, setOverride] = useState<null | Pick<
    FeesRow,
    'price' | 'currencyCode'
  >>(() => {
    const overrideValue = isOverridden(subscriptionFees)

    if (!overrideValue) return null

    return {
      currencyCode: subscriptionFees.flat()[0].currencyCode,
      price: overrideValue.fees['Management fee'],
    }
  })

  const [overrideWithDifferentPrices, setOverrideWithDifferentPrices] =
    useState(() =>
      overrideDifferentPrices
        ? isOverriddenWithDifferentPrices(
            subscriptionFees,
            formCurrenciesByCountry
          )
        : false
    )

  const contractSupportedCountries = Object.entries(supportedRegions).filter(
    ([countryCode]) => regionIsSupported(countryCode)
  )

  // other mgmt fees if more than 1 fee per country is Subscription
  const hasOtherManagementFees = useMemo(
    () =>
      Object.values(formFeesByCountryByType)
        .map((s) =>
          Object.values(s).filter(
            ({ billingType }) => billingType === 'Subscription'
          )
        )
        .some((countryFees) => countryFees.length > 1),
    [formFeesByCountryByType]
  )

  // when region no longer supported, empty its value
  useEffect(() => {
    Object.keys(supportedRegions)
      .filter((regionCode) => !regionIsSupported(regionCode))
      .forEach((notSupportedRegion) =>
        updateFeePrice(
          {
            target: { value: NOT_SUPPORTED },
          } as React.FocusEvent<HTMLInputElement>,
          notSupportedRegion,
          'Management fee',
          NOT_SUPPORTED
        )
      )
  }, [supportedRegions, regionIsSupported, updateFeePrice])

  useEffect(() => {
    updateAllOverrideCurrencyCode(
      overrideWithDifferentPrices || override
        ? defaultOverrideCurrencyCode
        : undefined,
      'Subscription'
    )
  }, [
    overrideWithDifferentPrices,
    override,
    defaultOverrideCurrencyCode,
    updateAllOverrideCurrencyCode,
  ])

  const getDefaultValue = (countryCode: string) => {
    const settingsValue = getFeeForCountryCode(countryCode, 'Management fee')
    return settingsValue === NOT_SUPPORTED ? undefined : settingsValue
  }

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 500, mb: 2 }}>
        Management Fees
      </Typography>
      {hasOtherManagementFees && (
        <Typography sx={{ mt: -2, mb: 2 }} variant="subtitle1">
          Other Management fees also exist for this client. You are editing the
          primary Management fee
        </Typography>
      )}
      {renderPrepaidSeats()}
      <EditOverrideManagementFees
        formCurrenciesByCountry={formCurrenciesByCountry}
        updateFeePrice={updateFeePrice}
        regionsToOverride={regionsToOverride}
        override={override}
        setOverride={setOverride}
        defaultsToRegion={defaultsToRegion}
        overrideWithDifferentPrices={overrideWithDifferentPrices}
        setOverrideWithDifferentPrices={setOverrideWithDifferentPrices}
      />
      {!override && (
        <Card variant="outlined" sx={{ maxWidth: '858px', marginBottom: 4 }}>
          {contractSupportedCountries.length > 0 ? (
            <TableContainer>
              <Table aria-label="management fees table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: 1, width: '50%' }}>
                      <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                        Region
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: 1, width: '50%' }}>
                      <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                        Monthly price
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {contractSupportedCountries.map(([countryCode, region]) => (
                    <TableRow
                      key={`Management fee - ${countryCode}`}
                      data-testid={`Management fee - ${countryCode}`}
                    >
                      <TableCell sx={{ padding: 1 }}>
                        <Typography variant="inherit">{region}</Typography>
                      </TableCell>
                      <TableCell sx={{ padding: 1 }}>
                        <TextField
                          id={`Management fee input - ${countryCode}`}
                          // when support for region changes, reset field value
                          key={regionIsSupported(countryCode).toString()}
                          defaultValue={getDefaultValue(countryCode)}
                          placeholder={
                            !getDefaultValue(countryCode)
                              ? NOT_SUPPORTED
                              : undefined
                          }
                          onBlur={(event) =>
                            updateFeePrice(
                              event,
                              countryCode,
                              'Management fee',
                              getFeeForCountryCode(
                                countryCode,
                                'Management fee'
                              ).toString(),
                              overrideWithDifferentPrices
                                ? defaultOverrideCurrencyCode
                                : undefined
                            )
                          }
                          fullWidth
                          disabled={!regionIsSupported(countryCode)}
                          label="Price"
                          size="small"
                          inputProps={{
                            'data-testid': `Management fee input - ${countryCode}`,
                          }}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            startAdornment: getFeeForCountryCode(
                              countryCode,
                              'Management fee'
                            ) !== NOT_SUPPORTED && (
                              <InputAdornment position="start">
                                {
                                  supportedCurrencies[
                                    overrideWithDifferentPrices
                                      ? defaultOverrideCurrencyCode
                                      : formCurrenciesByCountry[countryCode]
                                  ]
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="subtitle1" sx={{ p: 2, textAlign: 'center' }}>
              No regions configured
            </Typography>
          )}
        </Card>
      )}
    </>
  )
}

export default ManagementFeesTableForm
