import React from 'react'
import { useQuery } from '@apollo/client'
import { Skeleton } from '@mui/material'

import { CategorySelect } from '@firstbase/components/molecules/CategorySelect'
import { GET_NEW_PRODUCT_DATA } from '@firstbase/data/SKU/queries'
import {
  getNewProductData,
  getNewProductDataVariables,
} from '@firstbase/data/SKU/__generated__/getNewProductData'

interface OwnProps {
  category: string
  setCategory: React.Dispatch<React.SetStateAction<string>>
}

// TODO: Refactor this with CategorySelect
export const WarrantyMetadataConfigCategorySelect = ({
  category,
  setCategory,
}: OwnProps) => {
  const { data, loading } = useQuery<
    getNewProductData,
    getNewProductDataVariables
  >(GET_NEW_PRODUCT_DATA, {
    variables: { pagingAndSorting: { pageSize: 1000, pageNumber: 1 } },
  })

  if (loading) return <Skeleton variant="rectangular" width={300} height={50} />

  return (
    <CategorySelect
      sx={{ width: '300px' }}
      data={data}
      category={category}
      setCategory={setCategory}
    />
  )
}
