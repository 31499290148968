import React from 'react'
import { SKU } from '@firstbase/types/Product'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  SxProps,
} from '@mui/material'
import { sentenceCase } from 'change-case'
import {
  getNewProductData,
  getNewProductData_getAllCategories_data,
} from '@firstbase/data/SKU/__generated__/getNewProductData'

interface OwnProps {
  data?: getNewProductData
  product?: SKU
  category: string
  setCategory:
    | React.Dispatch<React.SetStateAction<string>>
    | ((category: string) => void)
  setToInitialMetadata?: () => void
  sx?: SxProps
}

// TODO: Refactor to be a reusable component
export const CategorySelect = ({
  data,
  product,
  category,
  setCategory,
  setToInitialMetadata,
  sx,
}: OwnProps) => {
  const renderCategories = () => {
    if (!data) return null

    const allCategories = [...data!.getAllCategories!.data]
    const categoryData = product
      ? allCategories.filter(
          ({ skuType }) => skuType === product?.category.skuType
        )
      : allCategories.filter(({ visible }) => visible)

    return categoryData
      .sort(
        (
          a: getNewProductData_getAllCategories_data,
          b: getNewProductData_getAllCategories_data
        ) =>
          a.nameSingular.toLowerCase() > b.nameSingular.toLowerCase() ? 1 : -1
      )
      .map(
        ({ code, nameSingular }: getNewProductData_getAllCategories_data) => (
          <MenuItem key={code} value={code}>
            {nameSingular}
          </MenuItem>
        )
      )
  }
  return (
    <FormControl sx={sx}>
      <InputLabel id="category">Category</InputLabel>
      <Select
        id="category"
        labelId="category-label"
        value={category}
        label="Category"
        fullWidth
        onChange={(e) => {
          setCategory((e.target as HTMLInputElement).value)
          if (setToInitialMetadata) setToInitialMetadata()
        }}
      >
        {renderCategories()}
      </Select>
      {!!product && (
        <Typography sx={{ ml: 1 }} variant="caption">
          Category may only be one of {sentenceCase(product?.category.skuType)}{' '}
          type
        </Typography>
      )}
    </FormControl>
  )
}
