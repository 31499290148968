import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import Page from '@firstbase/components/atoms/Page'
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { GET_ALL_WAREHOUSES } from '@firstbase/data/Warehouse/queries'
import {
  getAllWarehouses,
  getAllWarehouses_getAllWarehouses_data as WarehouseRow,
} from '@firstbase/data/Warehouse/__generated__/getAllWarehouses'
import { primaryWarehouses } from '@firstbase/constants/warehouse'
import {
  CREATE_PERSON_FOR_ORGANIZATION,
  CREATE_NEW_ORGANIZATION,
  UPDATE_ORGANIZATION_BY_SLUG,
} from '@firstbase/data/Organization/mutations'
import { graphqlRequest } from '@firstbase/utils/request'
import Loader from '@firstbase/components/atoms/Loader'
import Select from '@firstbase/components/atoms/FBSelect'
import { getOrganizationById_getOrganizationById as Organization } from '@firstbase/data/Organization/__generated__/getOrganizationById'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { format, parse } from 'date-fns'
import {
  GroupName,
  OrganizationStatus,
  PauseReason,
  MergeTenant,
} from '@globalTypes'
import {
  CreatePersonForOrganization,
  CreatePersonForOrganizationVariables,
} from '@firstbase/data/Organization/__generated__/CreatePersonForOrganization'
import { getAllCountries } from '@firstbase/data/Geo/__generated__/getAllCountries'
import { GET_ALL_COUNTRIES } from '@firstbase/data/Geo/queries'
import {
  updateContractProducts as UpdateContractProducts,
  updateContractProductsVariables,
} from '@firstbase/data/ProductTier/__generated__/UpdateContractProducts'
import { UPDATE_CONTRACT_PRODUCTS } from '@firstbase/data/ProductTier/contractMutations'
import { useGetOrganizationContract } from '../useGetOrganizationContract'
import { GET_CONTRACTS_BY_ORGANIZATION } from '@firstbase/data/ProductTier/contractQueries'

import { SupportedProductTiersTable } from './SupportedProductTiersTable'
import isEqual from 'lodash.isequal'

type OwnProps = {
  client?: Organization
  handleClose: (flushData?: boolean) => void
  open: boolean
  version: 'EDIT' | 'CREATE'
}

type Admin = {
  firstName?: string
  lastName?: string
  email?: string
  countryCode?: string
}

type AdminRequest = Admin & {
  hasSuccess: boolean
  personIndex: number
}

type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>
}
type NonNulledAdminRequest = NoUndefinedField<AdminRequest>

interface OrgResponseData {
  id: string
  slug: string
}

type OrgResponse = {
  data: {
    createOrganization?: OrgResponseData
    UpdateOrganization?: OrgResponseData
  }
  errors: [{ message: string }]
}

const MAX_LOGO_FILE_SIZE = 1000000 // 1Mb in Bytes

const fileReader = new FileReader()
const parsePOS = (date: string) => {
  return parse(date, 'yyyy-MM-dd', new Date())
}

function ClientDetailsForm({ client, open, handleClose, version }: OwnProps) {
  const apolloClient = useApolloClient()
  const [imageUrl, setImageUrl] = useState<string>(
    client?.whiteLabel?.logoUrl || ''
  )
  const [status, setStatus] = useState<string>(client?.status || 'LIVE')
  const [orgName, setOrgName] = useState<string>(client?.name || '')

  const [hardwareRenewalRate, setHardwareRenewalRate] = useState<number>(
    client?.refreshMonths || 0
  )
  const [machineRetentionLength, setMachineRetentionLength] = useState<number>(
    client?.machineRetentionLength || 0
  )
  const [abmNumber, setAbmNumber] = useState<string>(client?.abmNumber || '')
  const [code, setCode] = useState<string>(client?.code || '')
  const [allowHAAS, setAllowHAAS] = useState<boolean>(
    client?.allowHAAS || false
  )
  const [subscriptionLength, setSubscriptionLength] = React.useState(
    client?.allowHAAS ? client?.subscriptionLength || 0 : 0
  )
  const [hasSubscriptionProducts] = useState<boolean>(
    (client?.productInformation?.totalElements ?? 0) > 0 || false
  )
  const [workatoAccountID, setWorkatoAccountID] = useState<number | null>(
    client?.integrations?.workatoCustomerAccountId || null
  )
  const [packageMappingTableId, setPackageMappingTableId] = useState<
    number | null
  >(client?.integrations?.packageMappingTableId || null)
  const [accentColor, setAccentColor] = useState<string>(
    client?.whiteLabel?.accent || ''
  )
  const [mergeTenant, setMergeTenant] = useState<string | null>(
    client?.integrations?.mergeTenant || null
  )
  const [labelLogo, setLabelLogo] = useState<File>()
  const [warehouses, setWarehouses] = useState<
    Record<string, WarehouseRow | null>
  >({})
  const [seeAllWarehouses, setSeeAllWarehouses] = useState<boolean>(false)
  const adminOneState = useState<Admin>({})
  const adminTwoState = useState<Admin>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [createPersonSuccess, setCreatePersonSuccess] = useState([false, false])
  const [pos, setPos] = useState(
    client?.pos ? parsePOS(client.pos).toLocaleDateString() : null
  )
  const [pauseReason, setPauseReason] = useState<PauseReason | null>(
    client?.pauseReason || null
  )
  const [showReasonHelpText, setShowReasonHelpText] = useState(false)

  const { data: warehouseData } = useQuery<getAllWarehouses>(
    GET_ALL_WAREHOUSES,
    { skip: !!client }
  )
  const { data: regionsData } = useQuery<getAllCountries>(GET_ALL_COUNTRIES)
  const apiCountries = useMemo<{ value: string; label: string }[]>(() => {
    if (!regionsData) return []

    const codes: { value: string; label: string }[] = []

    regionsData.getAllCountries.map((c) => {
      codes.push({
        value: c.countryCode,
        label: c.name,
      })
    })

    return codes
  }, [regionsData])

  const [createPersonMutation] = useMutation<
    CreatePersonForOrganization,
    CreatePersonForOrganizationVariables
  >(CREATE_PERSON_FOR_ORGANIZATION)

  const handleUploadLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.target || !e.target.files) return
    const file = e.target.files[0]

    if (file.size > MAX_LOGO_FILE_SIZE) {
      return setError(
        'The selected logo image is too large. Please select a smaller image.'
      )
    }

    setLabelLogo(file)
  }

  function updateSelectedWarehouse(
    event: React.ChangeEvent<HTMLInputElement>,
    warehouse: WarehouseRow
  ): void {
    if (version === 'CREATE') {
      setWarehouses((prevState) => ({
        ...prevState,
        [warehouse.name]: event.target.checked ? { ...warehouse } : null,
      }))
    }
  }

  const saveClient = async () => {
    const formData = new FormData()
    const hasWhiteLabel = Boolean(accentColor || labelLogo)
    const operation = {
      query:
        version === 'CREATE'
          ? CREATE_NEW_ORGANIZATION
          : UPDATE_ORGANIZATION_BY_SLUG,
      variables: {
        name: orgName.trimEnd(),
        status: status,
        refreshMonths: hardwareRenewalRate,
        abmNumber: abmNumber,
        code,
        allowHAAS: allowHAAS,
        subscriptionLength: subscriptionLength,
        machineRetentionLength: machineRetentionLength,
        ...(version === 'EDIT' && {
          slug: client!.slug,
          pos: pos ? format(new Date(pos), 'yyyy-MM-dd') : null,
          pauseReason,
        }),
        ...(version === 'CREATE' && {
          warehouseIds: Object.values(warehouses)
            .filter((warehouse: WarehouseRow | null) => warehouse)
            .map((warehouse: WarehouseRow | null) => warehouse?.id),
        }),
        ...(hasWhiteLabel && {
          whiteLabeled: hasWhiteLabel,
          whiteLabel: { ...(accentColor && { accent: accentColor }) },
        }),
        integrations: {
          workatoCustomerAccountId: workatoAccountID || 0,
          packageMappingTableId: packageMappingTableId || 0,
          mergeTenant,
        },
      },
    }
    formData.append('operations', JSON.stringify(operation))

    if (labelLogo) {
      const map = {
        '0': ['variables.logo'],
      }
      formData.append('map', JSON.stringify(map))
      formData.append('0', labelLogo as Blob)
    }
    return graphqlRequest<OrgResponse>({
      method: 'post',
      url: '/graphql',
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Job-Title': `mgmt-client:${window.location.pathname}`,
      },
      data: formData,
    })
  }

  const assignAdmins = async (orgSlug: string) => {
    if (version === 'EDIT') return

    const adminPromises = [
      {
        ...adminOneState[0],
        hasSuccess: createPersonSuccess[0],
        personIndex: 0,
      },
      {
        ...adminTwoState[0],
        hasSuccess: createPersonSuccess[1],
        personIndex: 1,
      },
    ]
      .filter(
        (admin: AdminRequest): admin is NonNulledAdminRequest =>
          !!admin.firstName &&
          !!admin.lastName &&
          !!admin.email &&
          !!admin.countryCode &&
          !admin.hasSuccess
      )
      .map((admin: NonNulledAdminRequest) => {
        return createPersonMutation({
          variables: {
            slug: orgSlug,
            createPerson: {
              forename: admin.firstName,
              surname: admin.lastName,
              email: admin.email,
              groups: [GroupName.APP_ADMIN],
              address: {
                countryCode: admin.countryCode,
              },
            },
          },
        }).then(() =>
          setCreatePersonSuccess((prevSuccesses) =>
            prevSuccesses.map((success, successIndex) =>
              successIndex === admin.personIndex ? true : success
            )
          )
        )
      })

    return Promise.all(adminPromises)
  }

  const createdOrg = useRef<OrgResponseData>()

  const posReasonValid = () => {
    return !pos || pauseReason !== null
  }

  const updatePauseReason = (value: PauseReason | null) => {
    setPauseReason(value)
    setShowReasonHelpText(false)
  }

  const { contracts, contractProductTiers } = useGetOrganizationContract(
    client?.id
  )

  const contractId = contracts?.[0]?.id
  const contractRevisionId = contracts?.[0]?.revisionId
  const contractProductTierIds = useMemo(
    () => contractProductTiers.map(({ id }) => id),
    [contractProductTiers]
  )
  const [selectedProductTierIds, setSelectedProductTierIds] = useState(
    contractProductTierIds
  )
  const [updateContractProductTiers] = useMutation<
    UpdateContractProducts,
    updateContractProductsVariables
  >(UPDATE_CONTRACT_PRODUCTS, {
    refetchQueries: [GET_CONTRACTS_BY_ORGANIZATION],
  })

  const handleSaveOrganization = async () => {
    if (!posReasonValid()) {
      setShowReasonHelpText(true)
      return
    } else setShowReasonHelpText(false)

    const createAdmins = async (orgSlug: string) => {
      await assignAdmins(orgSlug)
      handleClose(true)
    }

    try {
      setIsSubmitting(true)

      if (
        client &&
        contractId &&
        contractRevisionId &&
        version === 'EDIT' &&
        selectedProductTierIds.length > 0 &&
        !isEqual(contractProductTierIds, selectedProductTierIds)
      ) {
        const productTierIdsToAdd = selectedProductTierIds.filter(
          (id) => !contractProductTierIds.includes(id)
        )
        const productTierIdsToRemove = contractProductTierIds.filter(
          (id) => !selectedProductTierIds.includes(id)
        )

        await updateContractProductTiers({
          variables: {
            params: {
              contractId,
              revisionId: contractRevisionId,
              productIdsToAdd: productTierIdsToAdd,
              productIdsToRemove: productTierIdsToRemove,
            },
          },
        })
      }

      if (
        createdOrg.current &&
        createPersonSuccess.some((success) => !success) &&
        version === 'CREATE'
      ) {
        // this condition occurs when org was created
        // but admin(s) failed
        // retrying save will only save admins

        await createAdmins(createdOrg.current.slug)
      } else {
        const { data: orgResponse, errors: createOrgError } = await saveClient()

        if (orgResponse && version === 'CREATE') {
          const org = orgResponse.createOrganization!

          try {
            await createAdmins(org.slug)
          } catch (e) {
            createdOrg.current = orgResponse.createOrganization
            throw e
          }
        }

        if (createOrgError) {
          setError(createOrgError[0].message)
        } else if (version === 'EDIT' && client) {
          apolloClient.cache.evict({
            id: `Organization:${client.id}`,
          })
          apolloClient.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'getAllOrganizations',
          })
          handleClose(true)
        }
      }
    } catch (err: any) {
      setError(err.message)
      setIsSubmitting(false)
    }
  }

  const accentColorIsValid = useMemo(() => {
    const regx = RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)

    return !accentColor || regx.test(accentColor)
  }, [accentColor])

  const continueProps = {
    label: 'Save',
    disabled: !orgName || isSubmitting || !accentColorIsValid,
    onClick: handleSaveOrganization,
  }

  const renderWhiteLabelColor = () => {
    if (!accentColorIsValid) return null

    return (
      <Box
        sx={{
          width: '1rem',
          height: '1rem',
          mr: 0.5,
          backgroundColor: accentColor,
        }}
      />
    )
  }

  fileReader.onload = (event: ProgressEvent<FileReader>) => {
    const { result } = event.target as FileReader

    if (result) setImageUrl(result as string)
  }

  useEffect(() => {
    if (labelLogo) {
      fileReader.readAsDataURL(labelLogo)
    }
  }, [labelLogo])

  const handlePOSUpdate = (date: string | null) => {
    if (date === null && status === OrganizationStatus.INACTIVE) {
      setStatus('LIVE')
      setPauseReason(null)
    }

    setPos(date)
  }

  const renderStatusOptions = () => {
    const options = [
      <MenuItem key="LIVE" value="LIVE">
        Live
      </MenuItem>,
      <MenuItem key="DEMO" value="DEMO">
        Demo
      </MenuItem>,
    ]

    if (status == 'INACTIVE')
      options.push(
        <MenuItem key="INACTIVE" value="INACTIVE">
          Inactive
        </MenuItem>
      )

    return options
  }

  const renderMergeTenantOptions = () =>
    Object.values(MergeTenant).map((tenant) => (
      <MenuItem key={tenant} value={tenant}>
        {tenant}
      </MenuItem>
    ))

  const renderPOSUpdateInstructions = () => {
    if (status !== 'INACTIVE') return null

    return (
      <Alert severity="warning" sx={{ mt: 2 }}>
        To switch an account back to Live, clear the pause of service date
      </Alert>
    )
  }

  const renderCountryOptions = () => {
    return apiCountries.map(({ value, label }) => (
      <MenuItem value={value} key={value}>
        {label}
      </MenuItem>
    ))
  }

  const handleSubscriptionLength = (
    event: React.MouseEvent<HTMLElement>,
    newSubscriptionLength: number | null
  ) => {
    if (newSubscriptionLength !== null) {
      setSubscriptionLength(newSubscriptionLength)
      setAllowHAAS(newSubscriptionLength > 0)
    }
  }

  return (
    <FullscreenModal
      open={open}
      handleClose={handleClose}
      handleBack={handleClose}
      closeDisabled={false}
      title={version === 'CREATE' ? 'Add organization' : 'Edit organization'}
      errorText={error}
      continueProps={continueProps}
    >
      {isSubmitting && <Loader />}
      <Page.TitleSection
        sectionTitle="Organization details"
        sectionSubtitle="Set high level details for this organization"
      />
      <Box
        sx={{
          width: '564px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginBottom: '24px',
        }}
      >
        <TextField
          label="Legal name"
          inputProps={{
            'data-testid': 'Legal name',
          }}
          required
          helperText="Legal name must be provided"
          value={orgName}
          onChange={(e) => setOrgName(e.target.value.trimStart())}
          fullWidth
          size="small"
          placeholder="Org legal name"
          InputLabelProps={{ shrink: true }}
        />

        {!!client && !!contractId && version === 'EDIT' && (
          <SupportedProductTiersTable
            selectedProductTierIds={selectedProductTierIds}
            handleChange={setSelectedProductTierIds}
          />
        )}

        <Box sx={{ width: '100%', marginBottom: 2, display: 'flex' }}>
          <FormControl sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel size="small" id="status">
              Status
            </InputLabel>
            <Select
              fullWidth
              label="Status"
              inputProps={{
                'data-testid': 'Status',
              }}
              labelId="status"
              id="org-status"
              size="small"
              onBlur={(e) => setStatus(e.target.value)}
              onChange={(event) => setStatus(event.target.value)}
              value={status}
              disabled={status === 'INACTIVE' && pos !== null}
            >
              {renderStatusOptions()}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Pause of service date"
                inputFormat="MM/dd/yyyy"
                value={pos}
                onChange={handlePOSUpdate}
                renderInput={(params) => (
                  <TextField {...params} sx={{ flex: 1 }} size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>
        <FormControl error={showReasonHelpText}>
          <InputLabel size="small" id="reason-for-pos">
            Reason for pause of service
          </InputLabel>
          <Select
            label="Reason for pause of service"
            inputProps={{
              'data-testid': 'pos-reason-testid',
            }}
            labelId="reason-for-pos"
            id="pos-reason"
            size="small"
            onBlur={(e) => updatePauseReason(e.target.value as PauseReason)}
            onChange={(event) =>
              updatePauseReason(event.target.value as PauseReason)
            }
            value={pauseReason}
            required={pos !== null}
            disabled={!pos}
            fullWidth
          >
            <MenuItem value="CUSTOMER_CANCELLED">
              Customer cancelled service
            </MenuItem>
            <MenuItem value="PAYMENT_NEEDED">Customer payment needed</MenuItem>
          </Select>
          <FormHelperText>
            {showReasonHelpText ? 'Reason is required to pause service' : ''}
          </FormHelperText>
          {renderPOSUpdateInstructions()}
        </FormControl>

        <TextField
          label="Hardware renewal rate (in months)"
          inputProps={{
            'data-testid': 'Hardware renewal',
            min: 0,
          }}
          type="number"
          value={hardwareRenewalRate}
          onChange={(e) => setHardwareRenewalRate(parseInt(e.target.value))}
          fullWidth
          size="small"
        />
        <TextField
          label="Machine retention length (in days)"
          inputProps={{
            'data-testid': 'Machine retention',
            min: 0,
          }}
          type="number"
          value={machineRetentionLength}
          onChange={(e) => setMachineRetentionLength(parseInt(e.target.value))}
          fullWidth
          size="small"
        />
        <TextField
          label="ABM Number"
          inputProps={{
            'data-testid': 'ABM Number',
          }}
          value={abmNumber}
          onChange={(e) => setAbmNumber(e.target.value)}
          fullWidth
          size="small"
          placeholder="ABM Number"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Code"
          inputProps={{
            'data-testid': 'code',
          }}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          size="small"
          placeholder="Code"
          InputLabelProps={{ shrink: true }}
        />

        <InputLabel size="small" id="reason-for-pos">
          Haas Subscriptions
        </InputLabel>
        <ToggleButtonGroup
          color="primary"
          value={String(subscriptionLength)}
          exclusive
          onChange={handleSubscriptionLength}
          aria-label="text alignment"
        >
          <ToggleButton
            value="0"
            aria-label="left aligned"
            disabled={hasSubscriptionProducts}
          >
            Disabled
          </ToggleButton>
          <ToggleButton value="24" aria-label="centered">
            24 Months
          </ToggleButton>
          <ToggleButton value="36" aria-label="right aligned">
            36 Months
          </ToggleButton>
        </ToggleButtonGroup>

        <Typography
          variant="caption"
          color="textSecondary"
          sx={{ marginTop: '-24px' }}
          hidden={
            version === 'CREATE' || !allowHAAS || !hasSubscriptionProducts
          }
        >
          This organization has at least one Standard Catalog subscription SKU
          assigned to it. You need to unassign all Standard Catalog Subscription
          SKUs before you can disable haas subscriptions.
        </Typography>
        <Page.TitleSection
          sectionTitle="White label"
          sectionSubtitle="Enter specifications for white labeling"
        />
        <Box
          sx={{ display: 'flex', marginTop: '-24px', flexDirection: 'column' }}
        >
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Card sx={{ p: 2, width: 'fit-content' }}>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  style={{ maxWidth: '10rem' }}
                  data-testid="Uploaded Logo Image"
                />
              ) : (
                <label>No Logo selected</label>
              )}
            </Card>
            <Stack>
              <Button
                component="label"
                variant="outlined"
                sx={{ display: 'inline-block', width: 'fit-content' }}
              >
                Upload Logo
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .svg"
                  hidden
                  data-testid="File Uploader"
                  onChange={handleUploadLogo}
                />
              </Button>
              <Typography variant="caption">
                File must be a valid .jpg, .jpeg, .png, or .svg
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Typography variant="caption">
              <b>Note</b>: An .svg or .png is required for logos to be present
              in email templates.
            </Typography>
          </Box>
          <TextField
            label="Accent Color"
            sx={{ mt: 2 }}
            inputProps={{
              'data-testid': 'Accent Color',
            }}
            InputProps={{
              startAdornment: renderWhiteLabelColor(),
            }}
            value={accentColor}
            onChange={(e) => setAccentColor(e.target.value)}
            fullWidth
            size="small"
            error={!accentColorIsValid}
            helperText={
              !accentColorIsValid && 'Must enter a valid hex code (#000000)'
            }
            placeholder="Hex Code #FFFFFF"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>
      {version === 'CREATE' && (
        <>
          <Divider />
          <Page.TitleSection
            sectionTitle="Warehouses"
            sectionSubtitle="Select which warehouses will be added to this organization"
          />
          <Grid
            container
            direction="column"
            sx={{ marginBottom: '24px', maxHeight: '300px' }}
          >
            {warehouseData?.getAllWarehouses &&
              warehouseData.getAllWarehouses.data
                .filter(
                  (warehouse: WarehouseRow) =>
                    seeAllWarehouses ||
                    (primaryWarehouses.includes(warehouse.name) &&
                      warehouse.active)
                )
                .map((warehouse: WarehouseRow) => (
                  <Grid item xs="auto" key={warehouse.id}>
                    <Checkbox
                      onChange={(e) => updateSelectedWarehouse(e, warehouse)}
                      inputProps={{
                        // @ts-ignore
                        'data-testid': warehouse.name,
                      }}
                    />
                    {warehouse.name}
                  </Grid>
                ))}
            {!seeAllWarehouses &&
              warehouseData?.getAllWarehouses &&
              warehouseData.getAllWarehouses.data.some(
                (warehouse: WarehouseRow) =>
                  !primaryWarehouses.includes(warehouse.name)
              ) && (
                <Grid item xs="auto">
                  <Link
                    onClick={() => setSeeAllWarehouses(true)}
                    sx={{ cursor: 'pointer', marginLeft: '12px' }}
                  >
                    {' '}
                    See all warehouses{' '}
                  </Link>
                </Grid>
              )}
          </Grid>
          <Divider />
          <Page.TitleSection
            sectionTitle="Firstbase Admins"
            sectionSubtitle="Add Firstbase admin accounts for this organization"
          />
          <Box>
            <Typography sx={{ fontWeight: '500' }}>Note:</Typography>
            Primary emails cannot be the same as their main firstbase email,
            please enter a unique email address.
          </Box>
          {[adminOneState, adminTwoState].map(
            (
              [admin, setAdmin]: [Admin, Dispatch<SetStateAction<Admin>>],
              index
            ) => (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  marginTop: '24px',
                  flexWrap: 'wrap',
                }}
                key={`Admin ${index + 1}`}
                data-testid={`Admin Input ${index + 1}`}
              >
                <InputLabel
                  sx={{
                    fontWeight: '500',
                    marginBottom: '-12px',
                    flexBasis: '100%',
                  }}
                >
                  Admin {index + 1}
                </InputLabel>
                <TextField
                  label="First name"
                  inputProps={{
                    'data-testid': `First name ${index + 1}`,
                  }}
                  value={admin.firstName || ''}
                  onChange={(e) =>
                    setAdmin({ ...admin, firstName: e.target.value })
                  }
                  size="small"
                />
                <TextField
                  label="Last name"
                  inputProps={{
                    'data-testid': `Last name ${index + 1}`,
                  }}
                  value={admin.lastName || ''}
                  onChange={(e) =>
                    setAdmin({ ...admin, lastName: e.target.value })
                  }
                  size="small"
                />
                <TextField
                  label="Email"
                  inputProps={{
                    'data-testid': `Email ${index + 1}`,
                  }}
                  value={admin.email || ''}
                  onChange={(e) =>
                    setAdmin({ ...admin, email: e.target.value })
                  }
                  size="small"
                  helperText="e.g. matt+client@firstbasehq.com"
                />
                <FormControl sx={{ marginRight: 2, flex: 1 }}>
                  <InputLabel size="small" id="country-code-label">
                    Country
                  </InputLabel>
                  <Select
                    labelId="country-code-label"
                    label="Country"
                    id="country-code"
                    inputProps={{
                      'data-testid': `Country ${index + 1}`,
                    }}
                    size="small"
                    onChange={(e) =>
                      setAdmin({ ...admin, countryCode: e.target.value })
                    }
                    onBlur={(e) =>
                      setAdmin({ ...admin, countryCode: e.target.value })
                    }
                    value={admin.countryCode || ''}
                    sx={{ marginBottom: 2 }}
                  >
                    {renderCountryOptions()}
                  </Select>
                </FormControl>
              </Box>
            )
          )}
          <Box
            sx={{
              margin: '24px 0px -24px',
            }}
          >
            <Divider />
          </Box>
        </>
      )}

      <Page.TitleSection
        sectionTitle="Integrations"
        sectionSubtitle="Add integration details for this organization"
      />
      <Box
        sx={{
          width: '564px',
          margin: '0 0 24px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <TextField
          label="Workato Customer Account ID"
          inputProps={{
            'data-testid': 'Workato Account ID',
          }}
          value={workatoAccountID}
          onChange={(e) => setWorkatoAccountID(parseInt(e.target.value))}
          fullWidth
          size="small"
          type="number"
          placeholder="Workato Customer Account ID"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Package Mapping Table ID"
          inputProps={{
            'data-testid': 'Package Mapping Table ID',
          }}
          value={packageMappingTableId}
          onChange={(e) => setPackageMappingTableId(parseInt(e.target.value))}
          fullWidth
          size="small"
          type="number"
          placeholder="Package Mapping Table ID"
          InputLabelProps={{ shrink: true }}
        />
        <FormControl sx={{ marginRight: 2, flex: 1 }}>
          <InputLabel size="small" id="mergeTenant">
            Merge Tenant
          </InputLabel>
          <Select
            fullWidth
            label="Merge Tenant"
            inputProps={{
              'data-testid': 'Merge Tenant',
            }}
            labelId="mergeTenant"
            id="org-mergeTenant"
            size="small"
            onBlur={(e) => setMergeTenant(e.target.value)}
            onChange={(event) => setMergeTenant(event.target.value)}
            value={mergeTenant}
            disabled={status === 'INACTIVE' && pos !== null}
          >
            {renderMergeTenantOptions()}
          </Select>
        </FormControl>
      </Box>
    </FullscreenModal>
  )
}

export default ClientDetailsForm
