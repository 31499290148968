import React, { useMemo, useState } from 'react'

import { useGetProductTiers } from '@firstbase/hooks'
import { ProductTierStatus } from '@globalTypes'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Card,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

interface SupportedProductTiersTableProps {
  selectedProductTierIds: string[]
  handleChange: (selectedProductTierIds: string[]) => void
}

// TODO: there is a big issue with how we're adding product tiers to the table.
// We might have a lot of product tiers, but the select will only display as much as we request from the backend.
// Potentially, we should add a table with toggles for each product tier, and a search bar to filter them.
// I will leave this as is for now, but we should definitely revisit this.
export const SupportedProductTiersTable = ({
  selectedProductTierIds,
  handleChange,
}: SupportedProductTiersTableProps) => {
  const { productTiers } = useGetProductTiers()

  // TODO: add an active filter to the productTiers query
  const productTiersActiveUnselected = useMemo(() => {
    if (!productTiers) {
      return []
    }

    return productTiers.filter(
      (productTier) =>
        productTier.status === ProductTierStatus.ACTIVE &&
        !selectedProductTierIds.includes(productTier.id)
    )
  }, [productTiers, selectedProductTierIds])

  const productTiersSelected = useMemo(() => {
    if (!productTiers) {
      return []
    }

    return productTiers.filter((productTier) =>
      selectedProductTierIds.includes(productTier.id)
    )
  }, [productTiers, selectedProductTierIds])

  const [selectedProductTierId, setSelectedProductTierId] = useState<string>('')

  return (
    <Card variant="outlined">
      <Table aria-label="add product tiers table">
        {selectedProductTierIds.length > 0 && (
          <TableHead className="bg-neutral-300">
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {productTiersSelected.map(({ id, name }) => (
            <TableRow key={id} className="last-of-type:border-0">
              <TableCell>
                <Typography>{name}</Typography>
              </TableCell>

              <TableCell align="right">
                <IconButton
                  aria-label="edit product tier"
                  onClick={() =>
                    handleChange(
                      selectedProductTierIds.filter(
                        (productTierId) => productTierId !== id
                      )
                    )
                  }
                >
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter className="bg-neutral-300">
          <TableCell colSpan={2}>
            <div className="flex gap-3">
              <TextField
                select
                className="grow"
                label="Add Product Tier"
                value={selectedProductTierId}
                onChange={(e) => setSelectedProductTierId(e.target.value)}
              >
                {productTiersActiveUnselected.map((productTier) => (
                  <MenuItem key={productTier.id} value={productTier.id}>
                    {productTier.name}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                className="shrink-0"
                variant="contained"
                disabled={selectedProductTierId.length === 0}
                onClick={() => {
                  handleChange([
                    ...selectedProductTierIds,
                    selectedProductTierId,
                  ])
                  setSelectedProductTierId('')
                }}
              >
                Add
              </Button>
            </div>
          </TableCell>
        </TableFooter>
      </Table>
    </Card>
  )
}
