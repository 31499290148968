import StatusChip from '@firstbase/components/atoms/StatusChip'
import TextSkeleton from '@firstbase/components/atoms/TextSkeleton/TextSkeleton'
import { getOrganizationById_getOrganizationById as Organization } from '@firstbase/data/Organization/__generated__/getOrganizationById'
import { getContractsByOrg_getContractsByOrg_contracts } from '@firstbase/data/ProductTier/__generated__/GetContractsByOrg'
import { OrganizationStatus } from '@globalTypes'
import { Box, Stack, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import React from 'react'
import WhiteLabelView from './WhiteLabelView'
import { useGetOrganizationContract } from './useGetOrganizationContract'

type OwnProps = {
  client: Organization
  isLoading?: boolean
}

const OrganizationDetails = ({ client, isLoading }: OwnProps) => {
  const { contractProductTiers } = useGetOrganizationContract(client.id)
  const renderPOSInfo = () => {
    if (!client.pos) return null

    const formattedDate = format(parseISO(client.pos), 'MMM dd, yyyy')

    if (client.status === OrganizationStatus.INACTIVE) {
      return (
        <Typography variant="body1">Inactive since {formattedDate}</Typography>
      )
    }

    return (
      <Typography variant="body1">Pause service on {formattedDate}</Typography>
    )
  }

  return (
    <>
      <Box
        sx={{ width: '50%', display: 'flex', flexDirection: 'column', mt: 2 }}
      >
        <Stack gap={0.5}>
          <WhiteLabelView
            logoUrl={client.whiteLabel?.logoUrl}
            accent={client.whiteLabel?.accent}
            isLoading={isLoading}
          />
          <Box className="flex justify-between gap-3">
            <Typography sx={{ fontWeight: 600 }} className="text-nowrap">
              Product Tier:
            </Typography>
            <TextSkeleton
              display="inline-block"
              isLoading={isLoading}
              className="text-ellipsis max-w-full text-nowrap overflow-auto"
            >
              {getProductTierText(contractProductTiers)}
            </TextSkeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>Legal name:</Typography>
            <TextSkeleton display="inline-block" isLoading={isLoading}>
              {client.name}
            </TextSkeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>Status</Typography>
              <StatusChip
                type="organization"
                status={OrganizationStatus[client.status]}
                isLoading={isLoading}
              />
            </Box>
            {renderPOSInfo()}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              Hardware renewal rate:
            </Typography>
            <TextSkeleton display="inline-block" isLoading={isLoading}>
              {client.refreshMonths}
            </TextSkeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              Machine retention length:
            </Typography>
            <TextSkeleton display="inline-block" isLoading={isLoading}>
              {client.machineRetentionLength}
            </TextSkeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>ABM number:</Typography>
            <TextSkeleton display="inline-block" isLoading={isLoading}>
              {client.abmNumber || '-'}
            </TextSkeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>HaaS Pricing:</Typography>
            <TextSkeleton display="inline-block" isLoading={isLoading}>
              {!client.allowHAAS ? 'No' : `${client.subscriptionLength} months`}
            </TextSkeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              Workato Customer Account ID:
            </Typography>
            <TextSkeleton display="inline-block" isLoading={isLoading}>
              {client.integrations?.workatoCustomerAccountId || '-'}
            </TextSkeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              Package Mapping Table ID:
            </Typography>
            <TextSkeleton display="inline-block" isLoading={isLoading}>
              {client.integrations?.packageMappingTableId || '-'}
            </TextSkeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>Code:</Typography>
            <TextSkeleton display="inline-block" isLoading={isLoading}>
              {client.code || '-'}
            </TextSkeleton>
          </Box>
        </Stack>
      </Box>
    </>
  )
}

export default OrganizationDetails

const getProductTierText = (
  productTiers: getContractsByOrg_getContractsByOrg_contracts['productTiers']
) => {
  if (productTiers.length === 0) {
    return '-'
  }

  return productTiers.map((tier) => tier.name).join(' + ')
}
