/**
 * Format a timestamp using browser default region, and timezone
 * @param timestamp the timestamp to format
 * @param formatOptions format options, default to long date and medium time
 * @returns the formatted timestamp
 */
export const formatTimestamp = (
  timestamp?: string | null,
  formatOptions: Intl.DateTimeFormatOptions = {
    dateStyle: 'long',
    timeStyle: 'medium',
  }
): string => {
  let formatted = timestamp ?? ''
  try {
    if (timestamp && timestamp.length > 0) {
      formatted = new Intl.DateTimeFormat('default', formatOptions).format(
        Date.parse(timestamp)
      )
    }
  } catch {}
  return formatted
}

/**
 * Gets the long formatted date representation of a timestamp
 * @param timestamp the timestamp to format
 * @param assumeUTC when true, formatting assumes the timezone is UTC otherwise use client zone
 * @returns the formatted timestamp
 */
export const timestampAsLongDate = (
  timestamp?: string | null,
  assumeUTC: boolean = false
): string => {
  return formatTimestamp(timestamp, {
    dateStyle: 'long',
    timeZone: assumeUTC ? 'UTC' : undefined,
  })
}
