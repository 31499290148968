import React from 'react'
import { useQuery } from '@apollo/client'
import { Skeleton } from '@mui/material'

import { GET_NEW_PRODUCT_DATA } from '@firstbase/data/SKU/queries'
import {
  getNewProductData,
  getNewProductDataVariables,
} from '@firstbase/data/SKU/__generated__/getNewProductData'
import { CategorySelect } from '@firstbase/components/molecules/CategorySelect'
import { VendorSelect } from '@firstbase/components/molecules/VendorSelect'

import { CommonProps } from './types'

interface OwnProps extends CommonProps {}

// Group together to quickly reuse one existing query
export const CategoryBrandSelectGroup = ({ state, dispatch }: OwnProps) => {
  const { data, loading } = useQuery<
    getNewProductData,
    getNewProductDataVariables
  >(GET_NEW_PRODUCT_DATA, {
    variables: { pagingAndSorting: { pageSize: 1000, pageNumber: 1 } },
  })

  if (loading)
    return (
      <>
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={40} />
      </>
    )

  return (
    <>
      <CategorySelect
        data={data}
        category={state.category}
        setCategory={(category: string) =>
          dispatch({ type: 'SET_CATEGORY', payload: category })
        }
      />
      <VendorSelect
        data={data}
        vendor={state.vendor.code}
        setVendor={(code: string, name: string) =>
          dispatch({ type: 'SET_VENDOR', payload: { code, name } })
        }
        hideCreateOption
      />
    </>
  )
}
