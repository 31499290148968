import React, { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  useQuery as useGQLQuery,
  useMutation as useApolloMutation,
} from '@apollo/client'
import Page from '@firstbase/components/atoms/Page'
import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import AllProductsTable from './AllProductsTable'
import AddProductDetails from './AddProductDetails'
import request from '@firstbase/utils/request'
import { PricingRow } from '../Product'
import Loader from '@firstbase/components/atoms/Loader'
import { CREATE_SKU_ASSIGNMENT } from '@firstbase/data/SKU/mutations'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { ClientResponse } from '@firstbase/types/Client'
import {
  createSKUAssignment,
  createSKUAssignmentVariables,
} from '@firstbase/data/SKU/__generated__/createSKUAssignment'
import { ContractResponse } from '@firstbase/types/Contract'
import { ProductPageParams } from '@firstbase/types/Pages'
import { Supplier } from '@firstbase/types/Product'
import ProductSettingsTitleSection from '../ProductSettingsTitleSection'
import { GET_ORGANIZATION_BY_ID } from '@firstbase/data/Organization/queries'

import { MinimumInventoryWithUpdatedQuantity } from '../productTypes'
import {
  filterOutEmptyMinimumInventories,
  doesEmptyMinimumInventoryExist,
} from '../productUtils'

type OwnProps = {
  open: boolean
  clientId: string
  handleClose: (flushData?: boolean) => void
  clientName: string
}

export interface Product {
  title: string
  category: string
  brand: string
  id: string
  vendorSku: string
  vendorCode: string
  status: string
}

enum Pages {
  ADD_PRODUCT = 'ADD_PRODUCT',
  ADD_PRICE = 'ADD_PRICE',
}

function AddProduct({ clientId, open, handleClose, clientName }: OwnProps) {
  const params: ProductPageParams = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [currentPage, setCurrentPage] = useState<Pages>(Pages.ADD_PRODUCT)
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const [pricingData, setPricingData] = useState<PricingRow[]>([])
  const [minInventory, setMinInventory] = useState<
    MinimumInventoryWithUpdatedQuantity[] | null
  >(null)
  const [supplier, setSupplier] = useState<Supplier | null>(null)

  const { data: client } = useQuery<ClientResponse>(
    ['client', { clientId: params.clientId }],
    () => request<any>({ url: `/organizations/${params.clientId}` })
  )

  const onBack = () => {
    if (currentPage === Pages.ADD_PRICE) {
      setPricingData([])
      return setCurrentPage(Pages.ADD_PRODUCT)
    }

    return handleClose()
  }

  const { data: contractResponse } = useQuery<ContractResponse>(
    ['client contract', { clientId }],
    () =>
      request<ContractResponse>({
        url: `/organizations/${clientId}/contract`,
      })
  )

  const { data: { getOrganizationById: orgClient } = {} } = useGQLQuery(
    GET_ORGANIZATION_BY_ID,
    {
      variables: { orgId: clientId },
    }
  )

  const onSuccess = useCallback(() => {
    enqueueSnackbar(`Successfully added ${selectedProduct?.title}`)
    handleClose(true)
  }, [enqueueSnackbar, handleClose, selectedProduct?.title])

  const {
    isLoading: isSavingPrices,
    mutate: savePrices,
    error: submitError,
  } = useMutation(
    () =>
      request({
        method: 'post',
        url: '/prices',
        data: { prices: pricingData },
      }),
    {
      onSuccess,
    }
  )

  const [saveProduct, { loading: isSavingProduct }] = useApolloMutation<
    createSKUAssignment,
    createSKUAssignmentVariables
  >(CREATE_SKU_ASSIGNMENT)

  const PageTitle = useMemo(() => {
    if (currentPage === Pages.ADD_PRODUCT) {
      return (
        <Page.TitleSection
          sectionTitle="Select Product"
          sectionSubtitle="Search and select a single product from the Firstbase catalog"
        />
      )
    }

    return (
      <ProductSettingsTitleSection
        productName={selectedProduct?.title || ''}
        organizationName={clientName}
      />
    )
  }, [clientName, currentPage, selectedProduct])

  const renderPageContents = () => {
    if (currentPage === Pages.ADD_PRODUCT || !selectedProduct) {
      return (
        <AllProductsTable
          clientId={clientId}
          setSelectedProduct={setSelectedProduct}
          existingSelectedProductId={selectedProduct?.id}
        />
      )
    }

    return (
      <AddProductDetails
        clientName={client?.name!}
        clientId={clientId}
        selectedProduct={selectedProduct}
        setPricing={setPricingData}
        submitError={submitError as Error}
        setMinInventory={setMinInventory}
        minInventory={minInventory}
        currencyData={contractResponse?.currencies || []}
        supplier={supplier}
        setSupplier={setSupplier}
        allowSubscription={orgClient?.allowHAAS}
      />
    )
  }

  const isSubmitting = isSavingProduct || isSavingPrices

  const continueProps = useMemo(() => {
    const handleNext = () => setCurrentPage(Pages.ADD_PRICE)

    const handleSave = () => {
      if (submitError) {
        // submitError only when previous prices save failed
        // only retry prices save
        return savePrices()
      }

      saveProduct({
        variables: {
          createSKUAssignment: {
            vendorSku: selectedProduct!.vendorSku,
            vendorCode: selectedProduct!.vendorCode,
            organizationSlug: client!.slug,
            threshold: 1,
            minimums: minInventory
              ? filterOutEmptyMinimumInventories(minInventory)
              : null,
            // always use true when feature flag is off, since that is legacy/default behavior
            firstbaseSupplied: supplier === 'firstbase',
          },
        },
        onCompleted: () => {
          if (supplier === 'firstbase') return savePrices()
          return onSuccess()
        },
        refetchQueries: ['getOrganizationById'],
      })
    }

    if (currentPage === Pages.ADD_PRODUCT) {
      return {
        disabled: !selectedProduct,
        label: 'Next',
        onClick: handleNext,
      }
    }

    return {
      disabled:
        isSubmitting ||
        supplier === null ||
        (!!minInventory && doesEmptyMinimumInventoryExist(minInventory)),
      label: 'Save',
      onClick: handleSave,
    }
  }, [
    currentPage,
    client,
    isSubmitting,
    submitError,
    saveProduct,
    selectedProduct,
    minInventory,
    savePrices,
    onSuccess,
    supplier,
  ])

  const helperText = `Step ${
    currentPage === Pages.ADD_PRODUCT ? '1' : '2'
  } of 2`

  return (
    <FullscreenModal
      open={open}
      handleClose={handleClose}
      handleBack={onBack}
      closeDisabled={isSubmitting}
      title="Add new product"
      continueProps={continueProps}
      helperText={helperText}
    >
      {PageTitle}
      {renderPageContents()}
      {isSubmitting && <Loader />}
    </FullscreenModal>
  )
}

export default AddProduct
