import React from 'react'
import { SortDirections } from '@firstbase/components/atoms/Table'

import { PricingHistoryRow } from './PricingHistory'
import { formatTimestamp, timestampAsLongDate } from '@utils/datetime/format'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import { Link } from 'react-router-dom'

const firstbaseOrgId = '93035283-fad2-4bf2-9476-11a462f97369'
const historyColumns = [
  {
    header: 'Supported Region',
    defaultSort: SortDirections.asc,
    id: 'countryCodeHistory',
    cell: {
      value: ({ countryCode }: PricingHistoryRow) => <>{countryCode}</>,
    },
  },
  {
    header: 'Charged Currency',
    defaultSort: SortDirections.asc,
    id: 'currencyHistory',
    cell: {
      value: ({ currency }: PricingHistoryRow) => <>{currency}</>,
    },
  },
  {
    header: 'Price',
    defaultSort: SortDirections.asc,
    id: 'priceHistory',
    cell: {
      value: ({ currency, price }: PricingHistoryRow) => (
        <>
          {price.toLocaleString(undefined, {
            style: 'currency',
            currency,
          })}
        </>
      ),
    },
  },
  {
    header: 'Billing type',
    defaultSort: SortDirections.asc,
    id: 'billingTypeHistory',
    cell: {
      value: ({ billingType }: PricingHistoryRow) => <>{billingType}</>,
    },
  },
  {
    header: 'Start Date',
    defaultSort: SortDirections.asc,
    id: 'startDateHistory',
    cell: {
      value: ({ startDate }: PricingHistoryRow) => (
        <>{timestampAsLongDate(startDate, true)}</>
      ),
    },
  },
  {
    header: 'Created at',
    defaultSort: SortDirections.asc,
    id: 'createdAtHistory',
    cell: {
      value: ({ createdAt }: PricingHistoryRow) => (
        <>{formatTimestamp(createdAt)}</>
      ),
    },
  },
  {
    header: 'Created by',
    id: 'createdByHistory',
    cell: {
      value: ({ createdBy }: PricingHistoryRow) => (
        <>
          <Link
            to={interpolateQueryParamsInPath('person', {
              clientId: firstbaseOrgId || '',
              personSlug: createdBy?.slug || '',
            })}
          >
            {createdBy?.forename || ''} {createdBy?.surname || ''}
          </Link>
        </>
      ),
    },
  },
]

export const viewPricingHistoryTableColumns = [...historyColumns]
