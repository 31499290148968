import { ApolloCache } from '@apollo/client'
import { SkuMetadataFieldName } from '@globalTypes'
import { SKU_METADATA_OPTION_FIELD_VALUES } from '@firstbase/data/SKU/fragments'
import { SkuMetadataOptionFieldValues } from '@firstbase/data/SKU/__generated__/SkuMetadataOptionFieldValues'
import { getActiveAssignmentsForSku } from '@firstbase/data/SKU/__generated__/getActiveAssignmentsForSku'
import {
  getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues,
  getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues_values,
} from '@firstbase/data/SKU/__generated__/getAllSKUMetadataOptionValues'
import { getNewProductData } from '@firstbase/data/SKU/__generated__/getNewProductData'
import { getAllSKUs_getAllSKUs_data_metadata } from '@firstbase/data/SKU/__generated__/getAllSKUs'
import { DisplayMetadataItem, MetadataItem, MetadataOption } from './types'

export const APPLE_US = 'APPLE_US'
export const getAssignedOrgNames = (
  skuAssignments?: getActiveAssignmentsForSku
): string[] => {
  return (
    skuAssignments?.getActiveAssignmentsForSku.data.map(
      (assignment) => assignment.organization.name
    ) || []
  )
}

export const getValidMetadataFields = (
  skuMetadataData: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues[],
  isRequired: boolean
) => {
  return (
    skuMetadataData.filter((option) =>
      isRequired ? option.required : !option.required
    ) ?? []
  )
}

export const getVendorName = (
  vendorCode: string,
  data?: getNewProductData
): string => {
  return (
    data?.getAllVendors.data.find((vendor) => vendor.code === vendorCode)
      ?.name || ''
  )
}

const formatMetadataValue = (
  fieldName: SkuMetadataFieldName,
  value: string
): string => {
  if (value === 'N/A') {
    return ''
  }
  switch (fieldName) {
    case SkuMetadataFieldName.GENERATION:
      return `(${value})`
    case SkuMetadataFieldName.SCREEN_SIZE:
      return `${value}-inch`
    case SkuMetadataFieldName.RAM_CAPACITY:
      return `${value} RAM`
    case SkuMetadataFieldName.SSD_CAPACITY:
      return `${value} SSD`
    case SkuMetadataFieldName.CPU_CORES:
      return `${value}-Core CPU`
    case SkuMetadataFieldName.GPU_CORES:
      return `${value}-Core GPU`
    case SkuMetadataFieldName.POWER_PLUG_TYPE:
      return `${value} Plug`
    case SkuMetadataFieldName.TOUCH_SCREEN:
      return value === 'TRUE' ? 'Touch Screen' : ''
    default:
      return value
  }
}

export const getSkuCardTitle = (
  metadata: MetadataItem[] | DisplayMetadataItem[],
  skuMetadataData: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues[]
): string => {
  const fieldOrder = [
    SkuMetadataFieldName.SERIES,
    SkuMetadataFieldName.MODEL,
    SkuMetadataFieldName.SCREEN_SIZE,
    SkuMetadataFieldName.GENERATION,
  ]

  const titleParts = fieldOrder.map((fieldName) => {
    const skuItem = skuMetadataData.find((item) => item.name === fieldName)
    const metaItem = metadata.find((meta) => meta.fieldName === skuItem?.name)
    return metaItem ? formatMetadataValue(fieldName, metaItem.value) : ''
  })

  return titleParts.join(' ')
}

export const getSkuCardChips = (
  metadata: MetadataItem[] | DisplayMetadataItem[],
  skuMetadataData: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues[],
  vendor: String
): string[] => {
  const relevantMetadata =
    vendor === APPLE_US ? skuMetadataData.slice(3) : skuMetadataData.slice(4)
  const chips: string[] = []
  const processorInfo: { processor?: string; processorSpeed?: string } = {}

  relevantMetadata.forEach((skuItem) => {
    const metaItem = metadata.find((meta) => meta.fieldName === skuItem.name)
    if (!metaItem) return

    if (skuItem.name === SkuMetadataFieldName.PROCESSOR) {
      processorInfo.processor = metaItem.value
    } else if (skuItem.name === SkuMetadataFieldName.PROCESSOR_SPEED) {
      processorInfo.processorSpeed = metaItem.value
    } else {
      chips.push(formatMetadataValue(skuItem.name, metaItem.value))
    }
  })

  // Combine PROCESSOR and PROCESSOR_SPEED value together
  if (processorInfo.processor || processorInfo.processorSpeed) {
    chips.unshift(
      `${processorInfo.processor || ''} ${processorInfo.processorSpeed || ''}`
    )
  }

  return chips.filter(Boolean)
}

export const getOptionLabel = (option: string | MetadataOption) => {
  if (typeof option === 'string') return option
  return option.inputValue || option.label
}

const findOptionWithLabel = (label: string, options: MetadataOption[]) =>
  options.find((option) => option.label.toLowerCase() === label.toLowerCase())

/**
 * There's a few different ways to get the newly selected value, either
 * when the user presses enter (the string case below) OR when the user
 * clicks on the option. In order to pass the right one to the handler,
 * this function is used to wrap all of the logic to keep the component
 * as clean as possible. Plus, this can now be tested.
 */
export const getNewlySelectedValue = (
  newValue: null | string | MetadataOption,
  options: MetadataOption[]
) => {
  if (typeof newValue === 'string') {
    const optionObject = findOptionWithLabel(newValue, options)
    const optionAlreadyExists = !!optionObject
    return optionAlreadyExists ? optionObject : newValue
  }

  if (newValue?.inputValue) return newValue.inputValue

  return newValue
}

const getMetadataValueIdWithTempIdFallback = (
  fieldValues: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues['values'],
  metaValue: string | null
) => {
  return (
    fieldValues?.find((value) => value?.value === metaValue)?.id ||
    `${metaValue}-temp-id`
  )
}

export const formatExistingMetadata = (
  metadata: getAllSKUs_getAllSKUs_data_metadata[],
  skuMetadataData: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues[]
): MetadataItem[] => {
  return metadata.reduce<MetadataItem[]>((acc, metaItem) => {
    const field = skuMetadataData.find((item) => item.name === metaItem.field)

    // If no field is found or it doesn't have a valid ID, skip this metadata item
    if (!field || !field.id) return acc

    const valueId = getMetadataValueIdWithTempIdFallback(
      field.values,
      metaItem.value
    )

    acc.push({
      fieldId: field.id,
      fieldName: field.name,
      value: metaItem.value || '',
      valueId: valueId,
    })

    return acc
  }, [])
}

export const getMetadataFieldOptions = (
  fieldValues: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues_values[],
  formattedMetadata: MetadataItem[],
  fieldId: string
): MetadataOption[] => {
  const options = fieldValues.map((value) => {
    return {
      id: value.id,
      label: value.value,
    }
  })
  // Add temp-id values from metadata if they exist and belong to this field
  formattedMetadata.forEach((metaItem) => {
    if (metaItem.fieldId === fieldId && metaItem.valueId.endsWith('-temp-id')) {
      options.push({
        id: metaItem.valueId,
        label: metaItem.value,
      })
    }
  })

  return options
}

export const removeOptionInCache = (
  cache: ApolloCache<any>,
  optionId: string,
  fieldId: string
) => {
  const idInCache = `SkuMetadataOptionField:${fieldId}`
  const cachedField = cache.readFragment<SkuMetadataOptionFieldValues>({
    id: idInCache,
    fragment: SKU_METADATA_OPTION_FIELD_VALUES,
  })

  if (cachedField) {
    const updatedField: SkuMetadataOptionFieldValues = {
      ...cachedField,
      values: cachedField.values
        ? [...cachedField.values].filter((val) => val && val.id !== optionId)
        : null,
    }
    cache.writeFragment({
      id: idInCache,
      fragment: SKU_METADATA_OPTION_FIELD_VALUES,
      data: updatedField,
    })
  }
}

export const transformSkuMetadata = (
  metadata: getAllSKUs_getAllSKUs_data_metadata[]
) => {
  return metadata.map((meta) => ({
    fieldName: meta.field || '',
    value: meta.value || '',
  }))
}
